import { Component, OnInit, OnDestroy, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

import { distinctUntilChanged } from 'rxjs/operators';
import { cloneDeep, set, includes } from 'lodash';
import * as moment from 'moment';

import { untilDestroyed } from '@app/@core';
import { LocalStorageService, ExportModalComponent } from '@app/@shared';

import { TxdotService } from './txdot.service';
import { Project } from './@models';

@Component({
  selector: 'app-txdot',
  templateUrl: './txdot.component.html',
  styleUrls: ['./txdot.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TxdotComponent implements OnInit, OnDestroy {
  @ViewChild('tooltip', { static: false }) exportTooltip: NgbTooltip;
  projects: Project[] = [];
  totalItems = 0;
  order: string = null;
  highlighted = false;
  selectAllProjects = false;
  filterType = 'bid-details';
  metaData: any = {
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(1, 'year').format('YYYY-MM-DD'),
    sortBy: 'BidDateTime',
    sortOrder: 'asc',
    page: 1,
    limit: 100,
    search: null,
    searchTerm: null,
    savedSearch: null
  };
  model: any = {
    startDate: null,
    endDate: null,
    hKeywords: null,
    hTerms: null,
    hSearchIn: null,
    hAddedDate: null,
    savedSearch: false
  };

  get filterOptions(): any {
    return this.metaData;
  }

  set filterOptions(data: any) {
    this.metaData = { ...data };
  }

  stringified = data => JSON.stringify(data);

  @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
    this.localStorageService.removeItem('highlightIDs-TXDOT');
    this.localStorageService.setItem('txdotMetaData', null);
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private txdotService: TxdotService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe(queryParams => {
      const { sortBy, sortOrder, startDate, endDate, page, limit, search, searchTerm, savedSearch } = queryParams;
      this.metaData.sortBy = sortBy;
      this.metaData.sortOrder = sortOrder;
      this.metaData.startDate = startDate;
      this.metaData.endDate = endDate;
      this.metaData.search = search;
      this.metaData.searchTerm = searchTerm;
      this.metaData.savedSearch = savedSearch;
      if (Object.values(queryParams)?.length >= 6) this.getProjects(queryParams);
    });
  }

  ngOnDestroy() {
    // Needed for automatic unsubscribe with untilDestroyed
  }

  trackById(index: number, project: Project): string {
    return project?.Id;
  }

  manageSort(event: MouseEvent, key: string): void {
    this.order = this.order === 'asc' ? 'desc' : 'asc';
    this.metaData.sortBy = key;
    this.metaData.sortOrder = this.order;
  }

  changeHandler(event: MouseEvent, project: Project): void {
    const checked = (event?.target as HTMLInputElement)?.checked;
    this.hideTooltip();
    this.projects = this.projects.map(pr => (pr?.Id === project?.Id ? { ...pr, checked } : pr));
  }

  changeAllHandler(event: MouseEvent): void {
    const checked = (event?.target as HTMLInputElement)?.checked;
    this.hideTooltip();
    this.projects = this.projects.map(pr => ({ ...pr, checked }));
  }

  getProjects(filterOptions: any) {
    this.txdotService
      .getProjects(filterOptions)
      ?.pipe(untilDestroyed(this))
      .subscribe(projectsData => {
        this.localStorageService
          .getItem('highlightIDs-TXDOT')
          .pipe(untilDestroyed(this))
          .subscribe(projectIds => {
            const hIds = projectIds;
            const data = projectsData?.Data;
            this.projects = cloneDeep(data).map(project => ({
              ...project,
              highlighted: hIds?.length ? includes(hIds, project?.Id) : false,
              checked: hIds?.length ? includes(hIds, project?.Id) : false
            }));
          });
        this.totalItems = projectsData?.Total;
      });
  }

  getHighlightData(params: any): void {
    const { filter, date, search, searchTerm, savedSearch, keywords, presetEnabled, customEnabled } = params;

    // date range
    this.model.startDate = this.metaData.startDate;
    this.model.endDate = this.metaData.endDate;

    // filter
    this.model.hTerms = filter;

    // highlightDateRange
    this.model.hAddedDate = date;

    // search
    this.model.hKeywords = searchTerm;
    this.model.hSearchIn = search;

    // savedSearches
    if (savedSearch) {
      this.model.savedSearch = savedSearch;
      this.model.hKeywords = keywords;
      this.model.hAddedDate = date;
    } else {
      this.model.savedSearch = undefined;
      this.model.hAddedDate = undefined;
    }
    this.model.enabled = presetEnabled || customEnabled;
    if (this.model.enabled) this.getProjectIdsToHighlight();
  }

  getProjectIdsToHighlight(): void {
    this.txdotService
      .getProjectIdsToHighlight(this.model)
      ?.pipe(untilDestroyed(this))
      .subscribe((projectIds: string[]) => {
        this.localStorageService.setItem('highlightIDs-TXDOT', projectIds);
        this.projects = this.projects.map(project => ({
          ...project,
          highlighted: includes(projectIds, project?.Id)
        }));
      });
  }

  openExport(): void {
    const ids = this.projects.filter(pr => pr.checked).map(({ Id }) => Id);
    if (!ids?.length) {
      this.showTooltip();
    } else {
      const modalRef = this.modalService.open(ExportModalComponent, { size: 'lg' });
      modalRef.componentInstance.projectIds = ids;
      modalRef.componentInstance.service = this.txdotService;
      modalRef.result.then(result => {
        this.selectAllProjects = false;
        this.hideTooltip();
        this.projects.map(pr => ({ ...pr, checked: false }));
      });
    }
  }

  manageHighlighted(event: MouseEvent): void {
    if (!event) return;
    this.highlighted = !this.highlighted;
  }

  hideUnhighlighted(highlighted): boolean {
    return !highlighted && this.highlighted ? true : false;
  }

  clearHighlights(cleared: boolean): void {
    if (cleared) {
      this.projects = this.projects.map(pr => set(pr, 'highlighted', false) && set(pr, 'checked', false));
      this.localStorageService.removeItem('highlightIDs-TXDOT');
    }
  }

  showTooltip(): void {
    this.exportTooltip.open();
  }

  hideTooltip(): void {
    this.exportTooltip.close();
  }

  getFilterType(type: string): void {
    this.filterType = FilterTypes[type];
  }
}

export enum FilterTypes {
  Notices = 'bid-details',
  Plans = 'plans',
  Results = 'bid-results',
  PlanHolders = 'plan-holders',
  Tabs = 'bid-tabs'
}
