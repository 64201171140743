import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';

// Components
import { LoaderComponent } from './components/loader/loader.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { HighlightsComponent } from './components/highlights/highlights.component';
import { ExportModalComponent } from './components/export-modal/export-modal.component';

// Directives
import { BindQueryParamsDirective, HighlightDirective, BindParamsDirective, HREFDirective, HighlightKeywordDirective } from './directives';

// Pipes
import { DateFormatPipe, SafeHtmlPipe } from './pipes';

// Utils
import { MomentDateFormatter } from './utils';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce
    })
  ],
  declarations: [
    LoaderComponent,
    PaginationComponent,
    DateRangeComponent,
    DateRangePickerComponent,
    DropdownComponent,
    HighlightsComponent,
    ExportModalComponent,
    BindQueryParamsDirective,
    BindParamsDirective,
    HighlightDirective,
    HREFDirective,
    HighlightKeywordDirective,
    DateFormatPipe,
    SafeHtmlPipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxLoadingModule,
    LoaderComponent,
    PaginationComponent,
    DateRangeComponent,
    DateRangePickerComponent,
    DropdownComponent,
    HighlightsComponent,
    ExportModalComponent,
    BindQueryParamsDirective,
    BindParamsDirective,
    HighlightDirective,
    HREFDirective,
    HighlightKeywordDirective,
    DateFormatPipe,
    SafeHtmlPipe
  ],
  entryComponents: [ExportModalComponent],
  providers: [{ provide: NgbDateParserFormatter, useClass: MomentDateFormatter }]
})
export class SharedModule {}
