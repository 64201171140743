import { isUndefined } from 'lodash';

export const webStorage = (storage: Storage) => {
  return (
    target: object, // The prototype of the class
    key: string // The Storage key
  ): void => {
    Object.defineProperty(target, key, {
      enumerable: true,
      get: () => JSON.parse(storage.getItem(key)),
      set: (value: any) => {
        if (!isUndefined(value)) storage.setItem(key, JSON.stringify(value));
        else storage.removeItem(key);
      }
    });
  };
};

export const LocalStorage = webStorage(localStorage);
export const SessionStorage = webStorage(sessionStorage);
