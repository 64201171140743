import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { CoreModule } from '@core';
import { SharedModule } from '@shared';
import { AuthModule } from '@app/auth';
import { ShellModule } from './shell/shell.module';
import { HomeModule } from './home/home.module';
import { TxdotModule } from './txdot/txdot.module';
import { ProjectsModule } from './projects/projects.module';
import { ProjectModule } from './project/project.module';
import { SettingsModule } from './settings/settings.module';
import { VideoTutorialsModule } from './video-tutorials/video-tutorials.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    AmplifyAngularModule,
    CoreModule,
    SharedModule,
    ShellModule,
    HomeModule, // Order of modules is important for fallback route to work
    ProjectsModule,
    TxdotModule,
    SettingsModule,
    VideoTutorialsModule,
    ProjectModule,
    AuthModule,
    AppRoutingModule // Must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers: [AmplifyService],
  bootstrap: [AppComponent]
})
export class AppModule {}
