<!-- filters -->
<app-txdot-filters (filterType)="getFilterType($event)"></app-txdot-filters>

<router-outlet></router-outlet>

<div class="container">
  <!-- project-highlights -->
  <section>
    <app-highlights
      *ngIf="projects?.length"
      [type]="'txdot'"
      (highlightData)="getHighlightData($event)"
      (hideHighlighted)="manageHighlighted($event)"
      (clearHighlights)="clearHighlights($event)"
    ></app-highlights>
  </section>

  <!-- project-list -->
  <section class="row" [appBindParams]="stringified(filterOptions)">
    <div class="col-12">
      <ul class="list-group">
        <li class="list-group-item">
          <div class="d-flex justify-content-end">
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-toggle="modal"
              data-target="#exportModal"
              title="Download/Export"
              ngbTooltip="Please select one or more projects."
              [autoClose]="false"
              triggers="manual"
              placement="left"
              #tooltip="ngbTooltip"
              (click)="openExport()"
            >
              Export Selected
            </button>
          </div>
        </li>
        <li class="list-group-item d-none d-lg-block">
          <div class="row">
            <div class="col-12 col-md-2">
              <input type="checkbox" class="" id="selectAllProjects" [(ngModel)]="selectAllProjects" (change)="changeAllHandler($event)" />
              <a class="text-dark font-weight-bold underline" (click)="manageSort($event, 'BidDateTime')">Bid Date</a>
            </div>
            <div class="col-12 col-md-2">
              <a class="text-dark font-weight-bold underline">County/Hwy.</a>
            </div>
            <div class="col-12 col-md-2">
              <a class="text-dark font-weight-bold underline">Control No.</a>
            </div>
            <div class="col-12 col-md-6">
              <a class="text-dark font-weight-bold underline" (click)="manageSort($event, 'ProjectType')">Type</a>
            </div>
          </div>
        </li>
        <li
          class="list-group-item"
          *ngFor="let project of projects; let i = index; trackBy: trackById"
          [ngClass]="{ highlight: project?.highlighted, 'd-none': hideUnhighlighted(project?.highlighted) }"
        >
          <div class="row">
            <div class="col-12 col-md-2">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  [id]="project?.Id"
                  [(ngModel)]="project.checked"
                  (change)="changeHandler($event, project)"
                />
              </div>
              <label class="form-check-label ml-3" [for]="project?.Id">
                {{ project?.BidsReceivedUntilDateTime | date: 'MM/dd/yyyy' }}
              </label>
            </div>
            <div class="col-12 col-md-2">
              <div>
                <span>{{ project?.County }}</span>
              </div>
              <div>
                <span>{{ project?.Highway }}</span>
              </div>
            </div>
            <div class="col-12 col-md-2">
              <a href="#">{{ project?.ControlNumber }}</a>
            </div>
            <div class="col-12 col-md-6">
              <a href="#" [routerLink]="['../', 'project-details', project?.Id, this.filterType]">{{ project?.ProjectType }}</a>
              <div>
                <span class="small text-muted">Estimate: {{ project?.EstimatedCost }}</span>
              </div>
              <div>
                <span class="small text-muted">Low Bid: {{ project?.LowBid }} </span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>

  <!-- pagination -->
  <ng-container *ngIf="projects?.length">
    <app-pagination [collectionSize]="totalItems" [pageSize]="metaData?.limit" [(page)]="metaData.page"> </app-pagination>
  </ng-container>
</div>
