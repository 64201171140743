import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  Shell.childRoutes([{ path: 'projects', loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule) }]),
  Shell.childRoutes([{ path: ':location', loadChildren: () => import('./project/project.module').then(m => m.ProjectModule) }]),
  Shell.childRoutes([{ path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) }]),
  Shell.childRoutes([
    {
      path: 'tutorials',
      loadChildren: () => import('./video-tutorials/video-tutorials.module').then(m => m.VideoTutorialsModule)
    }
  ]),
  Shell.childRoutes([{ path: 'txdot', loadChildren: () => import('./txdot/txdot.module').then(m => m.TxdotModule) }]),
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
