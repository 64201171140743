import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { SavedSearch } from '@app/@core/models';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  searchesUrl = `${environment?.savedSearchBaseURL}/savedSearch`;

  constructor(private http: HttpClient) {}

  getSavedSearches(): Observable<string | SavedSearch[]> {
    return this.http.get<SavedSearch[]>(this.searchesUrl).pipe(
      map((data: SavedSearch[]) => data?.['Data']),
      catchError(() => of('Error, could not load saved searches'))
    );
  }

  getSavedSearch(id: string): Observable<string | SavedSearch> {
    return this.http.get<SavedSearch>(`${this.searchesUrl}/${id}`).pipe(catchError(() => of('Error, could not find saved search')));
  }

  deleteSavedSearch(id: string): Observable<string | SavedSearch> {
    return this.http.delete<SavedSearch>(`${this.searchesUrl}/${id}`).pipe(catchError(() => of('Error, could not find saved search')));
  }

  editSavedSearch(id: string, payload: any): Observable<string | SavedSearch> {
    return this.http
      .put<SavedSearch>(`${this.searchesUrl}/${id}`, payload)
      .pipe(catchError(() => of('Error, could not find saved search')));
  }

  createSavedSearch(data: SavedSearch): Observable<string | SavedSearch> {
    return this.http.post<SavedSearch>(this.searchesUrl, data).pipe(catchError(() => of('Could not save scan')));
  }
}
