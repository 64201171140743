import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Project } from '@app/@core/models/project';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  projectUrl = 'projects';

  constructor(private http: HttpClient) {}

  getProject(id: string): Observable<string | Project> {
    return this.http.get<Project>(`projects/${id}`).pipe(
      map((data: Project) => data?.['Data']),
      catchError(() => of('Error, could not load this project'))
    );
  }
}
