import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { untilDestroyed } from '@app/@core';
import { LocalStorage } from '@app/@shared';

import { AuthenticationService } from '@app/auth/authentication.service';

import * as moment from 'moment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  @LocalStorage isAuthenticated: boolean;

  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService
      .isUserAuthenticated()
      .pipe(untilDestroyed(this))
      .subscribe(authenticated => {
        this.isAuthenticated = authenticated;
      });
  }

  ngOnDestroy() {}

  goToProjects(event: MouseEvent): void {
    if (event)
      this.router.navigate(['projects', 'austin'], {
        queryParams: {
          category: 'bidding',
          sortBy: 'BidDateTime',
          sortOrder: 'asc',
          startDate: moment().format('YYYY-MM-DD'),
          endDate: moment().add(1, 'year').format('YYYY-MM-DD'),
          page: 1,
          limit: 150
        }
      });
  }
}
