import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

export function dateRangeArray(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);
  // Get range from start and end dates
  const dateRange = Array.from(moment.range(start, end).by('days'));
  // Format dates within range
  const dateRangeFormatted = dateRange.map(d => moment(d).format('YYYY-MM-DD'));
  return dateRangeFormatted;
}
