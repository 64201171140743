import { NgModule } from '@angular/core';

import { SharedModule } from '@app/@shared';
import { SettingsRoutingModule } from './settings-routing.module';
import { SavedSearchesComponent } from './saved-searches/saved-searches.component';
import { OtherSettingsComponent } from './other-settings/other-settings.component';
import { SearchesFormComponent } from './saved-searches/searches-form/searches-form.component';

@NgModule({
  imports: [SharedModule, SettingsRoutingModule],
  declarations: [SavedSearchesComponent, OtherSettingsComponent, SearchesFormComponent]
})
export class SettingsModule {}
