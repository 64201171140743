import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent implements OnInit {
  @Input() config: any;
  @Input() disabled: boolean;
  @Output() selectedOption: EventEmitter<any> = new EventEmitter<any>();
  isSelected = true;

  constructor() {}

  ngOnInit() {}

  onOptionSelected(event: MouseEvent, type: string): void {
    if (event) {
      const selection = { type, id: this.config.selectedOption };
      this.selectedOption.emit(selection);
    }
  }
}
