<!-- project-highlights -->
<section>
  <app-highlights
    [type]="'others'"
    (highlightData)="getHighlightData($event)"
    (hideHighlighted)="manageHighlighted($event)"
    (clearHighlights)="clearHighlights($event)"
    (openExport)="openExport()"
  ></app-highlights>
</section>

<!-- project-list -->
<section class="row mb-5">
  <div class="col-12">
    <ul class="list-group">
      <li class="list-group-item">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary"
            data-toggle="modal"
            data-target="#exportModal"
            title="Download/Export"
            ngbTooltip="Please select one or more projects."
            [autoClose]="false"
            triggers="manual"
            placement="left"
            #tooltipTop="ngbTooltip"
            (click)="openExport()"
          >
            Export Selected
          </button>
        </div>
      </li>
      <li class="list-group-item d-none d-lg-block">
        <div class="row">
          <div class="col-12 col-md-3 col-lg-2">
            <input type="checkbox" class="" id="selectAllProjects" [(ngModel)]="selectAllProjects" (change)="changeAllHandler($event)" />
          </div>
          <div class="col-12 col-md-1 col-lg-1"></div>
          <div class="col-12 col-md-2 col-lg-1">
            <a class="text-dark font-weight-bold underline" (click)="manageSort($event, 'BidDateTime')">Bid Date</a>
          </div>
          <div class="col-12 col-md-7 col-lg-8">
            <a class="text-dark font-weight-bold underline" (click)="manageSort($event, 'Title')">Name</a>
          </div>
        </div>
      </li>
      <ng-container *ngIf="data?.length">
        <li
          class="list-group-item"
          *ngFor="let project of data; let i = index; trackBy: trackById"
          appHighlight
          [project]="project"
          [ngClass]="{ highlight: project?.highlighted, 'd-none': hideUnhighlighted(project?.highlighted) }"
        >
          <div class="row">
            <div class="col-12 col-md-3 col-lg-2">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  [id]="project?.Id"
                  [(ngModel)]="project.checked"
                  (change)="changeHandler($event, project)"
                />
                <label class="form-check-label" [for]="project?.Id">
                  {{ project?.AmtekId }}
                </label>
              </div>
            </div>
            <div class="col-12 col-md-1 col-lg-1">
              <span class="badge badge-pill badge-success" *ngIf="project?.Addendum">A{{ project?.Addendum | slice: 9:11 }}</span>
            </div>
            <div class="col-12 col-md-2 col-lg-1">
              <span>{{ project?.BidDateTime | dateFormat: 'short' }}</span>
            </div>
            <div class="col-12 col-md-6 col-lg-7">
              <a href="#" (click)="goToDetails(project?.Id)">{{ project?.Title }}</a>
            </div>
          </div>
        </li>
      </ng-container>
      <li class="list-group-item bg-light">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary"
            data-toggle="modal"
            data-target="#exportModal"
            title="Download/Export"
            ngbTooltip="Please select one or more projects."
            [autoClose]="false"
            triggers="manual"
            placement="left"
            #tooltipBottom="ngbTooltip"
            (click)="openExport()"
          >
            Export Selected
          </button>
        </div>
      </li>
    </ul>
  </div>
</section>
