<div class="p-3 mb-4 border rounded note" *ngIf="bidResults?.Note">{{ bidResults?.Note }}</div>
<div>
  <ul class="list-group mb-4">
    <li class="list-group-item bg-light font-weight-bold">Bid Results</li>
    <li class="list-group-item" [ngClass]="{ 'd-none': bidResults?.Bidders?.length }">No bid results yet.</li>
    <li class="list-group-item" *ngFor="let bRes of bidResults?.Bidders; let i = index">
      <div class="row">
        <div class="col-12 col-md-4">
          <span appHighlightKeyword>{{ bRes?.Company }}</span>
        </div>
        <div class="col-12 col-md-2 d-flex justify-content-end">
          <span>${{ bRes?.BidAmount | number: '1.2' }}</span>
        </div>
        <div class="col-md-6">{{ bRes?.Note }}</div>
      </div>
    </li>
  </ul>
</div>
<div class="border rounded bg-light p-3">
  <div>
    <span>Bidders: </span>
    <span>{{ bidResults?.NumberOfBidders }}</span>
  </div>
  <div>
    <span>Average Bid: </span>
    <span>{{ averageBid }}</span>
  </div>
  <div>
    <span>AMLT $: </span>
    <span>{{ amlt }}</span>
  </div>
  <div>
    <span>AMLT %: </span>
    <span>{{ amltPercent }}</span>
  </div>
</div>
