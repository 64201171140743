import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalStorage } from '@app/@shared';

import { Logger } from '@core';

const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  @LocalStorage isAuthenticated: boolean;
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.isAuthenticated) return true;

    log.debug('Not authenticated, redirecting and adding redirect url...');
    this.router.navigate(['/'], { queryParams: { redirect: state.url }, replaceUrl: true });
    return false;
  }
}
