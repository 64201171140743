<div class="mt-4 d-flex justify-content-end">
  <ngb-pagination
    [collectionSize]="collectionSize"
    [pageSize]="pageSize"
    [(page)]="page"
    [maxSize]="5"
    [rotate]="true"
    [boundaryLinks]="true"
    (pageChange)="changedPage($event)"
  >
    <ng-template ngbPaginationFirst>First</ng-template>
    <ng-template ngbPaginationPrevious>Previous</ng-template>
    <ng-template ngbPaginationNext>Next</ng-template>
    <ng-template ngbPaginationLast>Last</ng-template></ngb-pagination
  >
</div>
