import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { omit, pick, cloneDeep } from 'lodash';
import { SharedDataService } from '@app/@shared';

import { BidDetailsTabComponent } from '../project-tab-content/bid-details-tab/bid-details-tab.component';
import { BidResultsTabComponent } from '../project-tab-content/bid-results-tab/bid-results-tab.component';
import { PlanHoldersTabComponent } from '../project-tab-content/plan-holders-tab/plan-holders-tab.component';
import { PlansTabComponent } from '../project-tab-content/plans-tab/plans-tab.component';
import { TabulationsTabComponent } from '../project-tab-content/tabulations-tab/tabulations-tab.component';

@Component({
  selector: 'app-project-tabs',
  templateUrl: './project-tabs.component.html',
  styleUrls: ['./project-tabs.component.css']
})
export class ProjectTabsComponent implements OnInit, OnDestroy {
  currentTab = 'bid-details';
  currentProject: any;
  tabs: any = [
    { id: 'bid-details', title: 'Bid Details', href: '#nav-bid-details' },
    { id: 'plan-holders', title: 'Plan Holders', href: '#nav-plan-holders' },
    { id: 'bid-results', title: 'Bid Results', href: '#nav-bid-results' },
    { id: 'tabulations', title: 'Tabs', href: '#nav-tabulations' },
    { id: 'plans', title: 'Plans', href: '#nav-plans' }
  ];

  @Input()
  set project(project: any) {
    this.currentProject = project;
  }

  constructor(private router: Router, private route: ActivatedRoute, private sharedDataService: SharedDataService) {
    const url = this.router.url;
    this.currentTab = url.substring(url.lastIndexOf('/') + 1);
  }

  ngOnInit() {}

  ngOnDestroy() {}

  trackByIndex(index: number): number {
    return index;
  }

  goToTab(tab: string) {
    this.currentTab = tab;
    this.router.navigate([tab], { relativeTo: this.route });
  }

  onRouterOutletActivate(event): void {
    switch (true) {
      case event instanceof BidDetailsTabComponent:
        const bidDetails = omit(cloneDeep(this.currentProject), ['PlanHolders', 'BidResults', 'Tabulations', 'PlansAndBidDocs']);
        this.sharedDataService.modifyData({ ...bidDetails });
        break;
      case event instanceof PlanHoldersTabComponent:
        const planHolders = pick(cloneDeep(this.currentProject), ['PlanHolders', 'PlanHolderLastUpdate']);
        this.sharedDataService.modifyData({ ...planHolders });
        break;
      case event instanceof BidResultsTabComponent:
        const bidResults = pick(cloneDeep(this.currentProject), ['BidResults']);
        this.sharedDataService.modifyData({ ...bidResults });
        break;
      case event instanceof TabulationsTabComponent:
        const tabulations = pick(cloneDeep(this.currentProject), ['Tabulations']);
        this.sharedDataService.modifyData({ ...tabulations });
        break;
      case event instanceof PlansTabComponent:
        const plansAndBidDocs = pick(cloneDeep(this.currentProject), ['PlansAndBidDocs']);
        this.sharedDataService.modifyData({ ...plansAndBidDocs });
        break;
      default:
        break;
    }
  }
}
