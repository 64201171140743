<div class="container-fluid">
  <div class="row py-2 bg-nav-filter" *ngIf="filters?.length">
    <div class="col-12">
      <nav class="d-flex align-items-center">
        <div class="dropdown" *ngFor="let filter of filters; let i = index; trackBy: trackByTitle">
          <a class="dropdown-toggle mr-3 text-white p-1" role="button" id="dropdownMenuLink" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            {{ filter?.Title }}
          </a>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item" *ngFor="let item of filter?.Values; let i = index; trackBy: trackByTitle"
              (click)="setDates(filter?.Title, item)">
              {{ item?.Month | dateFormat: 'MMM' }}. {{ item?.Year }}
            </a>
          </div>
        </div>
        <a class="mr-3 text-white p-1" data-toggle="collapse" href="#collapseCustom" role="button" aria-expanded="false"
          aria-controls="collapseCustom">Date Range</a>
        <a class="mr-3 text-white p-1" (click)="toggleReports($event)">Reports</a>
      </nav>

      <div class="collapse" id="collapseCustom">
        <app-date-range></app-date-range>
      </div>
    </div>
  </div>
</div>