import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  goToRoute(type: string): void {
    if (type === 'saved-searches') this.router.navigate(['settings', 'saved-searches']);
    else if (type === 'other') this.router.navigate(['settings', 'other']);
  }
}
