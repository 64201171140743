<!-- nav desktop-->
<div class="container-fluid">
  <div class="row bg-nav">
    <div class="col-12">
      <nav class="py-2 d-flex justify-content-between align-items-center">
        <div>
          <!-- <a routerLink="/home" class="mr-3 text-white" title="Home">
          <i class="fas fa-home fa-lg"></i>
        </a> -->
          <a (click)="goToLocation('txdot')" class="mr-3 text-white p-1"
            [ngClass]="{ 'active-link': isActive('txdot') }">TXDOT</a>
          <a (click)="goToLocation('austin')" class="mr-3 text-white p-1"
            [ngClass]="{ 'active-link': isActive('austin') }">CTX</a>
          <a (click)="goToLocation('dallas')" class="mr-3 text-white p-1"
            [ngClass]="{ 'active-link': isActive('dallas') }">DFW</a>
          <a (click)="goToLocation('houston')" class="mr-3 text-white p-1"
            [ngClass]="{ 'active-link': isActive('houston') }">HOU</a>
        </div>
        <div class="d-flex">
          <a class="mr-3 text-white" *ngIf="isAuthenticated" [routerLink]="['/logout']">Sign Out </a>
          <a (click)="goToRoute('tutorials')" class="mr-3 text-white"><i class="fas fa-video fa-lg"></i></a>
          <div class="dropdown">
            <a class="" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <i class="fas fa-cog fa-lg text-white"></i>
            </a>
            <app-settings></app-settings>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>


<!-- nav mobile-->
<!-- <nav class="p-3 d-flex d-md-none justify-content-end bg-nav">
  <div class="dropdown">
    <button
      class="btn btn-outline-light dropdown-toggle"
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="fas fa-bars"></i>
    </button>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
      <a routerLink="/" class="dropdown-item" title="Home">Home</a>
      <a (click)="goToLocation('txdot')" class="dropdown-item">TXDOT</a>
      <a (click)="goToLocation('austin')" class="dropdown-item">CTX</a>
      <a (click)="goToLocation('dallas')" class="dropdown-item">DFW</a>
      <a (click)="goToLocation('houston')" class="dropdown-item">HOU</a>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" (click)="goToRoute('settings')">My Saved Searches</a>
      <a class="dropdown-item" (click)="goToRoute('tutorials')">Tutorials</a>
    </div>
  </div>
</nav> -->