import { Component, OnInit, OnDestroy } from '@angular/core';

import { cloneDeep } from 'lodash';

import { untilDestroyed } from '@app/@core';
import { SharedDataService } from '@app/@shared';

import { PlanHolders } from '@app/@core/models';

@Component({
  selector: 'app-plan-holders-tab',
  templateUrl: './plan-holders-tab.component.html',
  styleUrls: ['./plan-holders-tab.component.css']
})
export class PlanHoldersTabComponent implements OnInit, OnDestroy {
  planHolders: PlanHolders;
  projectId: string;
  isLoading = false;

  constructor(private sharedDataService: SharedDataService) {}

  ngOnInit() {
    this.getPlanHolders();
  }

  ngOnDestroy() {}

  getPlanHolders() {
    this.sharedDataService.sharedData$
      .pipe(untilDestroyed(this))
      .subscribe((planHolders: any) => (this.planHolders = cloneDeep(planHolders)));
  }
}
