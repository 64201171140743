<div class="p-3 bg-danger text-white rounded" [ngClass]="{ 'd-none': tabulations?.length }">No bid tabs yet.</div>
<div>
  <div class="table-responsive" *ngIf="tabulations?.length">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th scope="col">ID 1</th>
          <th scope="col">ID 2</th>
          <th scope="col">Description</th>
          <th scope="col">Unit</th>
          <th scope="col">Qty</th>
          <th scope="col">Unit Price</th>
          <ng-container *ngFor="let bidder of bidders; let idx = index">
            <th scope="col" appHighlightKeyword>{{ bidder.BidderName }}</th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let bForm of bidForms; let idx = index">
          <tr>
            <td [attr.colspan]="tabulations.length + 6" class="bid-form-name text-white font-weight-bold">{{ bForm?.FormName }}</td>
          </tr>
          <ng-container *ngFor="let section of bForm?.Sections; let idx = index">
            <tr>
              <th [attr.colspan]="tabulations.length + 6" class="bg-light">{{ section?.SectionName }}</th>
            </tr>
            <tr *ngFor="let bI of section?.BidItems; let idex = index">
              <td>{{ bI?.ItemNo1 }}</td>
              <td>{{ bI?.ItemNo2 }}</td>
              <td appHighlightKeyword>{{ bI?.Description }}</td>
              <td>{{ bI?.Unit }}</td>
              <td>{{ bI?.Quantity }}</td>
              <td>{{ bI?.UnitPrice }}</td>
              <td *ngFor="let bidder of bidders; let idx = index">${{ bidder?.BidderUnitPrice | number: '1.2' }}</td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
