import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SavedSearchesComponent } from './saved-searches/saved-searches.component';
import { OtherSettingsComponent } from './other-settings/other-settings.component';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  {
    path: 'saved-searches',
    component: SavedSearchesComponent,
    data: { title: 'Saved Searches' }
  },
  {
    path: 'other',
    component: OtherSettingsComponent,
    data: { title: 'Other Settings' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class SettingsRoutingModule {}
