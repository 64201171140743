export const environment = {
  production: false,
  serverURL: 'https://kzyaa9z9nk.execute-api.us-east-1.amazonaws.com/',
  savedSearchBaseURL: 'https://bhxg7zu010.execute-api.us-east-1.amazonaws.com',
  txdotBaseURL: 'https://kbjorsdem5.execute-api.us-east-1.amazonaws.com/txdot',
  reportsBaseURL: 'https://xsddywvip9.execute-api.us-east-1.amazonaws.com/project/reports',
  txdotReportsBaseURL: 'https://ovr1t339n8.execute-api.us-east-1.amazonaws.com/txdot/project/reports',
  userManagementServer: 'https://amtek-dev.auth.us-east-1.amazoncognito.com',
  baseSiteURL: 'https://amtek.civcast.com',
  auth: {
    userPoolWebClientId: '69fpfd2ut12hsro1dgr48gusbv',
    userPoolId: 'us-east-1_OpPadfIKz',
    oauth: {
      domain: 'amtek-dev.auth.us-east-1.amazoncognito.com',
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `https://amtek.civcast.com`,
      redirectSignOut: `https://amtek.civcast.com`,
      responseType: 'code'
    },
    identityPoolId: 'us-east-1:a6087bc9-e88c-494d-83cf-02c1f4948c8b',
    region: 'us-east-1'
  }
};
