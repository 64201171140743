import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';

import { trimStart, findIndex } from 'lodash';
import * as moment from 'moment';

import { ProjectFilter } from '@app/@core/models/project-meta-data';
import { filters } from '@app/@shared/fixtures';

@Component({
  selector: 'app-project-filters',
  templateUrl: './project-filters.component.html',
  styleUrls: ['./project-filters.component.css']
})
export class ProjectFiltersComponent implements OnInit, OnDestroy {
  @Output() filterObject: EventEmitter<ProjectFilter> = new EventEmitter<ProjectFilter>();
  form = new FormGroup({
    category: new FormControl('bidding'),
    sortBy: new FormControl('BidDateTime'),
    sortOrder: new FormControl('desc'),
    startDate: new FormControl(moment().format('YYYY-MM-DD')),
    endDate: new FormControl(moment().add(1, 'year').format('YYYY-MM-DD')),
    tab:new FormControl('bid-details')
  });

  customSortDropdownConfig: any = {
    type: 'sort',
    options: [
      { id: 'BidDateTime', label: 'Bid Date Ascending', selected: false },
      { id: '-BidDateTime', label: 'Bid Date Descending', selected: false },
      { id: 'Title', label: 'Project Name Ascending', selected: false },
      { id: '-Title', label: 'Project Name Descending', selected: false }
    ],
    selectedOption: 'all'
  };

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.getFilter('bidding');
    this.form.valueChanges.subscribe(filter => this.getFilterChanges(filter));
    this.activatedRoute.queryParams.subscribe(queryParams => {
      const { sortBy, sortOrder } = queryParams;
      this.customSortDropdownConfig.selectedOption = sortOrder === 'desc' ? `-${sortBy}` : sortBy;
    });
  }

  ngOnDestroy() {}

  get startDate(): string {
    return this.form.controls?.startDate?.value;
  }

  get endDate(): string {
    return this.form.controls?.endDate?.value;
  }

  getFilter(type: string): void {
    const index = findIndex(filters, ['category', type]);
    const { category, sortBy, sortOrder, startDate, endDate, tab } = filters[index];
    this.form.patchValue({
      category,
      sortBy,
      sortOrder,
      startDate,
      endDate,
      tab
    });
    this.filterObject.emit(filters[index]);
  }

  getFilterChanges(filter: any): void {
    const { category } = filter;
    const index = findIndex(filters, ['category', category]);
    filters[index] = { ...filter };
    this.filterObject.emit({ ...filters[index] });
  }

  setFilter(event: MouseEvent, type: string): void {
    if (event) this.getFilter(type);
  }

  isActive(type: string): boolean {
    return !!(this.form.controls?.category?.value === type);
  }

  getCustomRange(range: any): void {
    const { startDate, endDate } = range;
    this.form.patchValue({
      category: 'custom',
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD')
    });
  }

  onSortOptionChange(value: any): void {
    const { id } = value;
    const sortBy = trimStart(id, '-');
    const sortOrder = id.includes('-') ? 'desc' : 'asc';
    this.form.patchValue({ sortBy, sortOrder });
  }
}
