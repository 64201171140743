import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[href]'
})
export class HREFDirective {
  @Input() public href: string | undefined;

  @HostListener('click', ['$event']) public onClick(event: Event): void {
    this.preventDefault(event);
  }

  private preventDefault(event) {
    if (!this.href || this.href === '#' || !this.href?.length) {
      event.preventDefault();
    }
  }
}
