<select
  id="inputState"
  class="form-control"
  [disabled]="disabled"
  [(ngModel)]="config.selectedOption"
  (change)="onOptionSelected($event, config.type)"
>
  <option *ngFor="let option of config.options; let i = index" [ngValue]="option.id" [selected]="option.selected">
    {{ option.label }}
  </option>
</select>
