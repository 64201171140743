import { Component, OnInit, OnDestroy } from '@angular/core';

import { flatMap, cloneDeep } from 'lodash';

import { untilDestroyed } from '@app/@core';
import { SharedDataService } from '@app/@shared';

import { Tabulation, BidForm } from '@app/@core/models';

@Component({
  selector: 'app-tabulations-tab',
  templateUrl: './tabulations-tab.component.html',
  styleUrls: ['./tabulations-tab.component.css']
})
export class TabulationsTabComponent implements OnInit, OnDestroy {
  tabulations: Tabulation[];
  bidderNames: string[];
  projectId: string;
  isLoading = false;
  data = [];

  constructor(private sharedDataService: SharedDataService) {}

  ngOnInit() {
    this.getTabulations();
  }

  ngOnDestroy() {}

  get tabulationsData(): any {
    const tabulations = this.tabulations;
    const bidForms = tabulations[0].BidForms;
    const formNames = flatMap(bidForms).map(form => form.FormName);
    const data = {
      formNames,
      sections: flatMap(flatMap(bidForms).map(s => s.Sections)),
      get sectionNames() {
        return this.sections.map(section => section.SectionName);
      },
      get sectionData() {
        return flatMap(
          this.sections.map(section => ({
            SectionName: section.SectionName,
            BidItems: section.BidItems
          }))
        );
      }
    };
    return data;
  }

  get bidders(): any {
    const tabulations = this.tabulations;
    const bidderUnitPrice = tab =>
      flatMap(flatMap(flatMap(tab.BidForms).map(s => s.Sections)).map(b => b.BidItems)).map(p => p.BidderUnitPrice)[0];
    const biddersData =
      tabulations &&
      tabulations.map(tab => {
        return {
          BidderName: tab.BidderName,
          BidderUnitPrice: bidderUnitPrice(tab)
        };
      });

    return biddersData;
  }

  get bidForms(): BidForm[] {
    const tabulations = this.tabulations;
    return tabulations && tabulations[0].BidForms;
  }

  getTabulations() {
    this.sharedDataService.sharedData$
      .pipe(untilDestroyed(this))
      .subscribe((tabulations: any) => (this.tabulations = cloneDeep(tabulations?.Tabulations)));
  }
}
