import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { cloneDeep, omit } from 'lodash';
import * as moment from 'moment';

import { untilDestroyed } from '@app/@core';
import { LocalStorageService } from '@app/@shared';

import { TxdotService } from '../txdot.service';
import { Filter } from '../@models';

@Component({
  selector: 'app-txdot-filters',
  templateUrl: './txdot-filters.component.html',
  styleUrls: ['./txdot-filters.component.css']
})
export class TxdotFiltersComponent implements OnInit, OnDestroy {
  @Output() filterType: EventEmitter<string> = new EventEmitter<string>();
  filters: Filter[] = [];
  sortBy: string = 'BidDateTime';
  sortOrder: string = 'asc';
  loadReports = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private txdotService: TxdotService
  ) {
    this.getDateFilters();
  }

  ngOnInit() {}

  ngOnDestroy() {
    // Needed for automatic unsubscribe with untilDestroyed
  }

  trackByTitle(index: number, filter: Filter): string {
    return filter?.Title;
  }

  getDateFilters(): void {
    this.txdotService
      .getDateFilters()
      ?.pipe(untilDestroyed(this))
      .subscribe(filtersData => {
        this.filters = cloneDeep(filtersData);
        const filter = filtersData[0]?.Values[0];
        this.updateQueryParams(this.formatDates(filter));
      });
  }

  setDates(title: string, filter: any): void {
    this.filterType.emit(title);
    this.updateQueryParams(this.formatDates(filter));
  }

  formatDates(value: any): any {
    const valueObj = moment({ year: value?.Year, month: value?.Month, day: 1 });
    const startDate = valueObj.format('YYYY-MM-DD');
    const endDate = valueObj.endOf('month').format('YYYY-MM-DD');
    return { startDate, endDate };
  }

  updateQueryParams(params: any): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        ...omit(this.activatedRoute.snapshot.queryParams, [...Object.keys(params)]),
        ...params,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder
      }
    });
  }

  toggleReports(event): void {
    if (event) this.loadReports = !this.loadReports;

    if (this.loadReports) {
      this.localStorageService.setItem('txdotMetaData', this.activatedRoute.snapshot.queryParams);
      this.router.navigate(['txdot', 'projects', 'reports']);
    } else {
      this.localStorageService
        .getItem('txdotMetaData')
        .pipe(untilDestroyed(this))
        .subscribe(data => {
          this.router.navigate(['txdot', 'projects'], {
            queryParams: { ...data }
          });
        });
    }
  }
}
