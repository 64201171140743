import { Directive, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { distinctUntilChanged } from 'rxjs/operators';

import { omitBy, isNull, mergeWith } from 'lodash';

@Directive({
  selector: '[appBindQueryParams]'
})
export class BindQueryParamsDirective implements OnInit {
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private ngControl: ControlContainer) {}

  ngOnInit() {
    this.ngControl.valueChanges.pipe(distinctUntilChanged()).subscribe(changes => {
      const mergedData = mergeWith({}, this.activatedRoute.snapshot.queryParams, { ...changes, page: 1 }, (a, b) =>
        b === null ? b : undefined
      );
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          ...this.activatedRoute.snapshot.queryParams,
          ...{ ...omitBy(mergedData, isNull) }
        }
      });
    });
  }
}
