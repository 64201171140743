import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { AuthenticationService } from '@app/auth/authentication.service';

/**
 * Adds JWT token to the 'Authorization' header.
 */
@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authenticationService.getAccessToken().pipe(
      mergeMap((result: string) => {
        if (result) {
          const authReq = request.clone({
            setHeaders: {
              Authorization: `Bearer ${result}`,
              'x-api-key': 'NXBchPgUn66wMyXlcWuT7ahxtFquLCkf2VudEVDc'
            }
          });
          return next.handle(authReq);
        }
        return next.handle(request);
      })
    );
  }
}
