import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Shell } from '@app/shell/shell.service';
import { ProjectComponent } from './project.component';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  {
    path: 'project/:id',
    component: ProjectComponent,
    data: { title: 'Project' },
    children: [{ path: '', loadChildren: () => import(`./project-tabs/project-tabs.module`).then(m => m.ProjectTabsModule) }]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class ProjectRoutingModule {}
