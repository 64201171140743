import { NgModule } from '@angular/core';

import { SharedModule } from '@shared';
import { ProjectsRoutingModule } from './projects-routing.module';

import { ProjectsComponent } from './projects.component';
import { ProjectFiltersComponent } from './project-filters/project-filters.component';
import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectHighlightSettingsModalComponent } from './project-highlight-settings-modal/project-highlight-settings-modal.component';

@NgModule({
  imports: [SharedModule, ProjectsRoutingModule],
  declarations: [ProjectsComponent, ProjectFiltersComponent, ProjectListComponent, ProjectHighlightSettingsModalComponent],
  entryComponents: [ProjectHighlightSettingsModalComponent]
})
export class ProjectsModule {}
