<ul class="list-group">
  <li class="list-group-item"><a href="#">Bidders Questionnaire</a> <span class="text-muted small">- Date</span></li>
  <li class="list-group-item"><a href="#">Prequalified Contractors</a> <span class="text-muted small">- Date</span></li>
  <li class="list-group-item">
    <a href="#">Construction Report: Contractors Work Summary</a> <span class="text-muted small">- Date</span>
  </li>
  <li class="list-group-item">
    <a href="#">Construction Report: Subcontractor Work Summary</a> <span class="text-muted small">- Date</span>
  </li>
</ul>
