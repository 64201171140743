import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoaderService } from '@app/@shared/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit, OnDestroy {
  constructor(public loaderService: LoaderService) {}

  ngOnInit() {}

  ngOnDestroy() {}
}
