import { Directive, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { set } from 'lodash';

import { LocalStorageService } from '../services/local-storage.service';

@Directive({
  selector: '[appHighlight]'
})
export class HighlightDirective implements AfterViewInit {
  @Input() project: any;

  constructor(private localStorageService: LocalStorageService, private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.localStorageService.getItem('highlightIDs-Others').subscribe(ids => {
      if ((ids || []).length) {
        const found = ids.find(u => u === this.project?.Id);
        return !!found
          ? set(this.project, 'highlighted', true) && set(this.project, 'checked', true)
          : set(this.project, 'highlighted', false) && set(this.project, 'checked', false);
      } else return set(this.project, 'highlighted', false) && set(this.project, 'checked', false);
    });
    this.cd.detectChanges();
  }
}
