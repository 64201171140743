import { NgModule } from '@angular/core';

//Modules
import { SharedModule } from '@app/@shared';
import { TxdotRoutingModule } from './txdot-routing.module';

//Components
import { TxdotComponent } from './txdot.component';
import { TxdotFiltersComponent } from './txdot-filters/txdot-filters.component';
import { ReportsMockupComponent } from './reports-mockup/reports-mockup.component';

@NgModule({
  imports: [SharedModule, TxdotRoutingModule],
  declarations: [TxdotComponent, TxdotFiltersComponent, ReportsMockupComponent]
})
export class TxdotModule {}
