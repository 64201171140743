import { Component, OnInit, OnDestroy } from '@angular/core';

import { cloneDeep } from 'lodash';

import { untilDestroyed } from '@app/@core';
import { SharedDataService } from '@app/@shared';

import { PlansAndBidDoc } from '@app/@core/models';

@Component({
  selector: 'app-plans-tab',
  templateUrl: './plans-tab.component.html',
  styleUrls: ['./plans-tab.component.css']
})
export class PlansTabComponent implements OnInit, OnDestroy {
  plansAndBidDocs: PlansAndBidDoc[];
  projectId: string;
  isLoading = false;

  constructor(private sharedDataService: SharedDataService) {}

  ngOnInit() {
    this.getPlans();
  }

  ngOnDestroy() {}

  get bidDocs(): PlansAndBidDoc[] {
    return this.plansAndBidDocs?.length && this.plansAndBidDocs.filter(doc => doc.IsPlans === 'False');
  }

  get planSheets(): PlansAndBidDoc[] {
    return this.plansAndBidDocs?.length && this.plansAndBidDocs.filter(doc => doc.IsPlans === 'True');
  }

  getPlans() {
    this.sharedDataService.sharedData$
      .pipe(untilDestroyed(this))
      .subscribe((plansAndBidDocs: any) => (this.plansAndBidDocs = cloneDeep(plansAndBidDocs)));
  }
}
