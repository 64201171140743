import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { untilDestroyed } from '@app/@core';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { LocalStorageService } from '../services/local-storage.service';

import { compact } from 'lodash';

@Directive({
  selector: '[appHighlightKeyword]'
})
export class HighlightKeywordDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef, private renderer: Renderer2, private localStorageService: LocalStorageService) {}

  ngAfterViewInit(): void {
    this.setBgColor();
  }

  ngOnDestroy(): void {}

  setBgColor(): void {
    const combinedParams = combineLatest([
      this.localStorageService.getItem('projectMetaData'),
      this.localStorageService.getItem('txdotMetaData')
    ]);
    combinedParams.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe(([local, txdot]) => {
      const keywords = `${local?.search?.hKeywords},${txdot?.hKeywords}`.split(',');
      const { innerHTML } = this.elementRef?.nativeElement;
      const match = compact(keywords.map(keyword => new RegExp(keyword, 'i').test(innerHTML)));
      if (match?.length) this.renderer.setStyle(this.elementRef.nativeElement, 'backgroundColor', 'lightYellow');
    });
  }
}
