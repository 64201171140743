<div class="row">
  <div class="form-group col-12 col-md-6">
    <div class="input-group">
      <input
        class="form-control"
        name="dpStart"
        placeholder="Start Date"
        aria-label="Start Date"
        [(ngModel)]="dateStart"
        ngbDatepicker
        #d="ngbDatepicker"
        (ngModelChange)="onDateChange($event)"
      />
      <div class="input-group-append">
        <button type="button" class="btn input-group-text" (click)="d.toggle()" ngbTooltip="Start Date">
          <i class="fa fa-calendar" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="form-group col-12 col-md-6">
    <div class="input-group">
      <input
        class="form-control"
        name="dpEnd"
        placeholder="End Date"
        aria-label="End Date"
        [(ngModel)]="dateEnd"
        ngbDatepicker
        #e="ngbDatepicker"
        (ngModelChange)="onDateChange($event)"
      />
      <div class="input-group-append">
        <button type="button" class="btn input-group-text" (click)="e.toggle()" ngbTooltip="End Date">
          <i class="fa fa-calendar" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</div>
