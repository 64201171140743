<!-- loader-on-route-change -->
<ngx-loading-bar [includeSpinner]="false" height="6px" color="#fff"></ngx-loading-bar>

<!-- loader-on-api-call -->
<app-loader></app-loader>

<!-- header -->
<app-header (locationChange)="getLocation($event)"></app-header>

<div class="bg-white">
  <!-- title -->
  <!--<section *ngIf="showRegion" class="row mb-3">
      <div class="col-12">
        <h2>{{ location }}</h2>
      </div>
    </section> -->

  <router-outlet></router-outlet>
</div>