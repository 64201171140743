import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

/*
 * ngbDatepicker input shows selected date in 'YYYY-MM-DD' format by default.
 * The below parser parses and formats the date input of type NgbDateStruct to 'MM/DD/YYYY'.
 * This parser is provided in shared.module.ts providers.
 */
@Injectable()
export class MomentDateFormatter extends NgbDateParserFormatter {
  readonly DATE_FORMAT = 'MM/DD/YYYY';

  parse(value: string): NgbDateStruct {
    if (value) value = value.trim();
    return null;
  }

  format(date: NgbDateStruct): string {
    if (!date) return '';
    const momentDate = moment([date.year, date.month - 1, date.day]);
    if (!momentDate.isValid()) return '';
    return momentDate.format(this.DATE_FORMAT);
  }
}
