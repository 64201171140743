<!-- select list date range and sort -->
<div class="container-fluid">
  <div class="row py-2 bg-nav-filter">
    <div class="col-12">
      <nav>
        <a class="mr-3 text-white p-1" (click)="setFilter($event, 'bidding')"
          [ngClass]="{ 'active-link': isActive('bidding') }">Bidding</a>
        <a class="mr-3 text-white p-1" (click)="setFilter($event, 'planHolders')"
          [ngClass]="{ 'active-link': isActive('planHolders') }">Plan Holders</a>
        <a class="mr-3 text-white p-1" (click)="setFilter($event, 'results')"
          [ngClass]="{ 'active-link': isActive('results') }">Results</a>
        <a class="mr-3 text-white p-1" (click)="setFilter($event, 'tabs')"
          [ngClass]="{ 'active-link': isActive('tabs') }">Tabs</a>
        <a class="mr-3 text-white p-1" (click)="setFilter($event, 'plans')"
          [ngClass]="{ 'active-link': isActive('plans') }">Plans</a>
        <a class="mr-3 text-white p-1" data-toggle="collapse" href="#collapseCustom" role="button" aria-expanded="false"
          aria-controls="collapseCustom">Date Range</a>
      </nav>
      <div class="collapse" id="collapseCustom">
        <form class="form-row pt-3" [formGroup]="form" appBindQueryParams>
          <div class="col-12 col-md-6 col-lg-6">
            <app-date-range-picker [defaultStart]="startDate" [defaultEnd]="endDate"
              (setRange)="getCustomRange($event)"></app-date-range-picker>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <app-dropdown [config]="customSortDropdownConfig" (selectedOption)="onSortOptionChange($event)"
              [disabled]="false"></app-dropdown>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>