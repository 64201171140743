import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { cloneDeep, set } from 'lodash';

import { LocalStorageService } from '@app/@shared';
import { untilDestroyed } from '@app/@core';

import { ProjectsService } from './projects.service';
import { Project } from '@app/@core/models';
import { ProjectMetaData, ProjectFilter } from '@app/@core/models/project-meta-data';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit, OnDestroy {
  projects: Project[] = [];
  filterObj: ProjectFilter;
  highlightProp = '';
  totalItems = 0;
  model: ProjectMetaData = {
    location: 'austin',
    filter: {
      hTerms: undefined,
      filterRange: { category: undefined, startDate: undefined, endDate: undefined }
    },
    sort: { sortBy: 'BidDateTime', sortOrder: 'desc' },
    search: { hSearchIn: undefined, hKeywords: undefined },
    savedSearch: false,
    keywords: [],
    page: 1,
    limit: 150,
    hAddedDate: undefined,
    enabled: false
  };

  @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
    this.localStorageService.removeItem('highlightIDs-Others');
    this.localStorageService.setItem('projectMetaData', null);
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private projectsService: ProjectsService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {}

  getData() {
    this.activatedRoute.params.subscribe(params => {
      this.model.location = params.location || 'austin';

      this.activatedRoute.queryParams.pipe(distinctUntilChanged()).subscribe(queryParams => {
        const { sortBy, sortOrder } = queryParams;
        this.model.sort = { sortBy, sortOrder };

        this.getProjects();
      });
    });
  }

  getFilterObject(filterObj: ProjectFilter): void {
    this.filterObj = filterObj;
    const { category, startDate, endDate, sortBy, sortOrder } = filterObj;
    this.model.sort = { sortBy, sortOrder };
    this.model.filter.filterRange = { category, startDate, endDate };

    this.getProjects();
  }

  getProjects(): void {
    this.projectsService
      .getProjects(this.model)
      ?.pipe(untilDestroyed(this))
      .subscribe((projects: any) => {
        this.projects = cloneDeep(projects?.Data);
        this.totalItems = projects?.Total;
      });
  }

  getProjectIdsToHighlight(): void {
    this.projectsService
      .getProjectIdsToHighlight(this.model)
      ?.pipe(untilDestroyed(this))
      .subscribe((projectIds: string[]) => {
        this.localStorageService.setItem('highlightIDs-Others', projectIds);
      });
  }

  getHighlightProps(prop: any): void {
    // tslint:disable-next-line: no-shadowed-variable
    const { filter, date, search, searchTerm, savedSearch, keywords, presetEnabled, customEnabled } = prop;

    // filter
    this.model.filter.hTerms = filter;

    // highlightDateRange
    this.model.hAddedDate = date;

    // search
    this.model.search.hKeywords = searchTerm;
    this.model.search.hSearchIn = search;

    // savedSearches
    if (savedSearch) {
      this.model.savedSearch = savedSearch;
      this.model.search.hKeywords = keywords;
      this.model.hAddedDate = date;
    } else {
      this.model.savedSearch = undefined;
      this.model.hAddedDate = undefined;
    }
    this.model.enabled = presetEnabled || customEnabled;
    if (this.model.enabled) this.getProjectIdsToHighlight();
  }

  clearHighlighted(cleared: boolean): void {
    if (cleared) {
      this.projects = this.projects.map(pr => set(pr, 'highlighted', false) && set(pr, 'checked', false));
      this.localStorageService.removeItem('highlightIDs-Others');
    }
  }

  getPageChange(page): void {
    this.model.page = page;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        ...this.activatedRoute.snapshot.queryParams,
        page
      }
    });
  }
}
