import { Component, OnInit, OnDestroy } from '@angular/core';
import { cloneDeep, meanBy, sortBy } from 'lodash';

import { untilDestroyed } from '@app/@core';
import { SharedDataService } from '@app/@shared';

import { BidResults } from '@app/@core/models';

@Component({
  selector: 'app-bid-results-tab',
  templateUrl: './bid-results-tab.component.html',
  styleUrls: ['./bid-results-tab.component.css']
})
export class BidResultsTabComponent implements OnInit, OnDestroy {
  bidResults: BidResults;
  projectId: string;
  isLoading = false;

  constructor(private sharedDataService: SharedDataService) {}

  ngOnInit() {
    this.getBidResults();
  }

  ngOnDestroy() {}

  get averageBid(): number {
    const avg = meanBy(this.bidResults && this.bidResults.Bidders, 'BidAmount');
    return typeof avg !== 'string' ? avg : 0;
  }

  /*
   * AMLT - Amount Left on Table
   * AMLT = Bidder 2 - Bidder 1
   * Bidder 1 = lowest amount
   * Bidder 2 - second lowest amount
   */
  get amlt(): number {
    const amounts = this.getAmounts();
    const amltValue = amounts[1] - amounts[0];
    return amounts && amounts.length && amltValue.toString() !== 'NaN' ? amltValue : 0;
  }

  /*
   * AMLT - Amount Left on Table
   * AMLT % = Bidder 2 - Bidder 1/Bidder 1
   * Bidder 1 = lowest amount
   * Bidder 2 - second lowest amount
   */
  get amltPercent(): number {
    const amounts = this.getAmounts();
    const amltPercentValue = (amounts[1] - amounts[0]) / amounts[0];
    return amounts && amounts.length && amltPercentValue.toString() !== 'NaN' ? amltPercentValue : 0;
  }

  getBidResults() {
    this.sharedDataService.sharedData$
      .pipe(untilDestroyed(this))
      .subscribe((bidResultsData: any) => (this.bidResults = cloneDeep(bidResultsData?.BidResults)));
  }

  getAmounts(): number[] {
    const bData = this.bidResults;
    let amounts: number[];
    if (bData && bData?.Bidders?.length) {
      const data = bData.Bidders.map(bidder => bidder.BidAmount);
      amounts = sortBy(data);
    }
    return amounts;
  }
}
