<!-- "Export " Modal -->
<div class="modal fade" id="exportModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Download</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Download reports for selected projects.</p>
        <ul class="list-group">
          <li class="list-group-item"><a href="#" (click)="export('projects', 'txt')">Project List (.txt)</a></li>
          <li class="list-group-item"><a href="#" (click)="export('projects', 'excel')">Project List (.xlsx)</a></li>
          <li class="list-group-item"><a href="#" (click)="export('bidInfo', 'txt')">Bid Info (.txt)</a></li>
          <li class="list-group-item d-flex justify-content-between">
            <a href="#" (click)="export('bidInfoAndItems', 'txt')"> Bid Info + Bid Items (.txt) </a>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="openPresetScans"
                data-toggle="collapse"
                data-target="#collapseBidInfo"
                aria-expanded="false"
                aria-controls="collapseBidInfo"
                [(ngModel)]="presetScansChecked"
                (change)="getSavedSearches()"
              />
              <label class="form-check-label" for="openPresetScans"> Apply preset scans.</label>
            </div>
          </li>
          <div class="collapse" id="collapseBidInfo">
            <div class="border p-3 mt-3 mb-3">
              <div class="mt-2 mb-2" *ngIf="savedSearchesDropdownConfig.options?.length">
                <select
                  class="form-control"
                  id="exampleFormControlSelect1"
                  [compareWith]="compareFn"
                  [(ngModel)]="savedSearchSelection"
                  (change)="getSavedSearch()"
                >
                  <option *ngFor="let option of savedSearchesDropdownConfig?.options; let i = index" [ngValue]="option">
                    {{ option?.Title }}
                  </option>
                </select>
                <div class="mt-2 mb-2">
                  <button type="button" class="btn btn-secondary" (click)="export('bidInfoAndItems', 'txt')">Download</button>
                </div>
              </div>
            </div>
          </div>
          <li class="list-group-item"><a href="#" (click)="export('planHolders', 'txt')">Plan Holders (.txt)</a></li>
          <li class="list-group-item"><a href="#" (click)="export('lowBidders', 'txt')">Low Bidders (.txt)</a></li>
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">Close</button>
      </div>
    </div>
  </div>
</div>
