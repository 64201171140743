<div>
  <ul class="list-group">
    <li class="list-group-item bg-light font-weight-bold">Plan Holders</li>
    <li class="list-group-item" [ngClass]="{ 'd-none': planHolders?.PlanHolders?.length }">No plan holders yet.</li>
    <li class="list-group-item" *ngFor="let planHolder of planHolders?.PlanHolders; let i = index">
      <div class="row">
        <div class="col-12 col-md-4" appHighlightKeyword>{{ planHolder?.Company }}</div>
        <div class="col-12 col-md-4">
          <div appHighlightKeyword>{{ planHolder?.Phone }}</div>
          <div appHighlightKeyword>{{ planHolder?.Fax }}</div>
        </div>
        <div class="col-12 col-md-4" appHighlightKeyword>{{ planHolder?.Email }}</div>
      </div>
    </li>
  </ul>
</div>
<div class="text-muted small mt-2">
  <span>Last Updated: </span><span>{{ planHolders?.PlanHolderLastUpdate | dateFormat: 'long' }}</span>
</div>
