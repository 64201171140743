import { Component, OnInit } from '@angular/core';
import { concat } from 'lodash';

import { SettingsService } from '../settings.service';
import { SavedSearch } from '@app/@core/models';

@Component({
  selector: 'app-saved-searches',
  templateUrl: './saved-searches.component.html',
  styleUrls: ['./saved-searches.component.css']
})
export class SavedSearchesComponent implements OnInit {
  savedSearches: SavedSearch[] = [];
  open = false;
  defaultConfig: any = {
    Id: '',
    Title: '',
    Keywords: '',
    DateAddedOption: ''
  };

  constructor(private settingsService: SettingsService) {}

  ngOnInit() {
    this.getSavedSearches();
  }

  openCard(event: MouseEvent): void {
    if (event) this.open = !this.open;
  }

  getSavedSearches() {
    this.settingsService.getSavedSearches().subscribe((data: SavedSearch[]) => (this.savedSearches = data));
  }

  getSavedSearchPayload(data: any, id: string, type: string): void {
    switch (type) {
      case 'create':
        this.settingsService.createSavedSearch(data).subscribe(res => {
          if (res?.['Code'] === 200) this.savedSearches = concat(this.savedSearches, res?.['Data']);
          return undefined;
        });
        break;
      case 'edit':
        this.settingsService.editSavedSearch(id, data).subscribe(res => {
          if (res?.['Code'] === 200)
            this.savedSearches = this.savedSearches.map(search => (search.Id === data.Id ? { ...search, ...data } : search));
          return undefined;
        });
        break;
      default:
        break;
    }
  }

  getDeleteId(id: string): void {
    this.settingsService.deleteSavedSearch(id).subscribe(res => {
      if (res?.['Data'] === 'Success') this.savedSearches = this.savedSearches.filter(search => search?.Id.toString() !== id);
    });
  }
}
