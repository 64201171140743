import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { cloneDeep } from 'lodash';

import { untilDestroyed } from '@app/@core';

import { ProjectService } from './project.service';
import { Project } from '@app/@core/models/project';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit, OnDestroy {
  project: Project;
  projectId: string;

  constructor(private projectService: ProjectService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(params => (this.projectId = params.id));
  }

  ngOnInit() {
    this.getProject();
  }

  ngOnDestroy() {}

  getProject() {
    this.projectService
      .getProject(this.projectId)
      .pipe(untilDestroyed(this))
      .subscribe((project: Project) => {
        this.project = cloneDeep(project) as Project;
      });
  }
}
