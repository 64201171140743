import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { compact, omit } from 'lodash';

import { SavedSearch } from '@app/@core/models';

@Component({
  selector: 'app-searches-form',
  templateUrl: './searches-form.component.html',
  styleUrls: ['./searches-form.component.css']
})
export class SearchesFormComponent implements OnInit {
  @Input() config: any;
  @Output() searchData: EventEmitter<SavedSearch> = new EventEmitter<SavedSearch>();
  @Output() deleteData: EventEmitter<string> = new EventEmitter<string>();
  formGroup: FormGroup;

  dropdownOptions: any = [
    { id: 'ALL', label: 'Scan projects added any time.' },
    { id: '2days', label: 'Scan projects added in the last 2 days.' },
    { id: '7days', label: 'Scan projects added in the last 7 days.' }
  ];

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    const { Id, Title, DateAddedOption, Keywords } = this.config;
    this.formGroup = this.formBuilder.group({
      Id,
      Title,
      keyword: '',
      Keywords: this.formBuilder.array([...Keywords.split(',').map(value => ({ value }))]),
      dateAdded: DateAddedOption
    });
  }

  get keywords(): FormArray {
    return this.formGroup.get('Keywords') as FormArray;
  }

  getDateOption(dateType: any): void {
    const dateAdded = dateType.id;
    this.formGroup.patchValue({ dateAdded });
  }

  newKeyword(): FormGroup {
    return this.formBuilder.group({
      qty: '',
      price: ''
    });
  }

  addKeywords(event): void {
    if (event) {
      const value = event instanceof KeyboardEvent ? event?.target?.['value'] : this.formGroup.controls['keyword']?.value;
      const formGroup = this.formBuilder.group({ value });
      this.keywords.push(formGroup);
      this.formGroup.patchValue({ keyword: '' });
    }
  }

  deleteKeywords(idx: number): void {
    this.keywords.removeAt(idx);
  }

  sendData(event): void {
    const data = {
      ...omit(this.formGroup.value, ['Id', 'Title', 'keyword', 'Keywords']),
      title: this.formGroup.value?.Title,
      keywords: compact(this.keywords.value.map(kw => kw.value)).join(','),
      userId: this.config?.UserId || undefined
    };
    if (event) this.searchData.emit(data);
    if (!this.config.Id) this.formGroup.reset();
  }

  sendDeletedData(event): void {
    const { Id } = this.config;
    if (event)
      if (Id) this.deleteData.emit(Id.toString());
      else this.formGroup.reset();
  }
}
