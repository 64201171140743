import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { upperCase } from 'lodash';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.css']
})
export class ShellComponent implements OnInit, OnDestroy {
  location = 'CTX';
  showRegion = false;

  constructor(private router: Router) {
    if (this.router.url.includes('projects') || this.router.url.includes('project')) this.showRegion = true;
    else this.showRegion = false;
  }

  ngOnInit() {
    this.router.events.pipe(filter(event => event instanceof ActivationEnd)).subscribe((event: ActivationEnd) => {
      const title = event?.snapshot?.routeConfig?.data?.title;
      if (!title) return;
      if (title && (title === 'Projects' || title === 'Project')) this.showRegion = true;
      else this.showRegion = false;
    });
  }

  ngOnDestroy() {}

  getLocation(location: string) {
    this.location = upperCase(location);
  }
}
