import { Component, OnInit, OnChanges, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.css']
})
export class DateRangePickerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() defaultStart: any;
  @Input() defaultEnd: any;
  @Output() setRange: EventEmitter<any> = new EventEmitter<any>();
  dateStart: NgbDateStruct;
  dateEnd: NgbDateStruct;

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    const startDate = moment(this.defaultStart);
    const endDate = moment(this.defaultEnd);
    this.dateStart = { year: startDate.year(), month: startDate.month() + 1, day: startDate.date() };
    this.dateEnd = { year: endDate.year(), month: endDate.month() + 1, day: endDate.date() };
  }

  ngOnDestroy() {}

  onDateChange(event: NgbDateStruct): void {
    if (this.dateStart && this.dateEnd) {
      // Convert date object to moment
      // Months range from 0 to 11 in moment whereas 1 to 12 in NgbDateStruct. Hence the subtraction
      const start = { year: this.dateStart?.year, month: +this.dateStart?.month - 1, day: this.dateStart?.day };
      const end = { year: this.dateEnd?.year, month: +this.dateEnd?.month - 1, day: this.dateEnd?.day };
      const startDate = moment(start).format('YYYY-MM-DD');
      const endDate = moment(end).format('YYYY-MM-DD');
      const range = { startDate, endDate };
      this.setRange.emit(range);
    }
  }
}
