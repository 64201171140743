import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { clone } from 'lodash';

import { untilDestroyed } from '@app/@core';
import { ProjectsService } from '@app/projects/projects.service';
import { TxdotService } from '@app/txdot/txdot.service';
import { SavedSearch } from '@app/@core/models';
import { SettingsService } from '@app/settings/settings.service';

@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.css']
})
export class ExportModalComponent implements OnInit, OnDestroy {
  projectIds: string[] = [];
  service: ProjectsService | TxdotService;
  presetScansChecked = false;
  savedSearchesDropdownConfig: any = {
    type: 'searches',
    options: [],
    selectedOption: 'ALL'
  };
  savedSearchSelection: SavedSearch = { Id: 'ALL', Title: 'Search Everything', Keywords: '', DateAddedOption: '', UserId: '' };

  constructor(public activeModal: NgbActiveModal, private settingsService: SettingsService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1?.Id === c2?.Id : c1 === c2;
  }

  export(type: string, format: string): void {
    // if (type === 'bidInfoAndItems') this.getSavedSearches();
    const payload = {
      type,
      format,
      Ids: clone(this.projectIds)
    };
    this.service
      .getReports({ ...payload })
      ?.pipe(untilDestroyed(this))
      .subscribe((base64Data: any) => {
        const url = window.URL.createObjectURL(new Blob([base64Data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = `${type}.${format === 'excel' ? 'xlsx' : 'txt'}`;
        link.click();
      });
  }

  close(): void {
    this.activeModal.dismiss();
  }

  getSavedSearches(): void {
    if (this.presetScansChecked && !this.savedSearchesDropdownConfig.options?.length)
      this.settingsService.getSavedSearches().subscribe((searches: SavedSearch[]) => {
        this.savedSearchesDropdownConfig.options = [{ Id: 'ALL', Title: 'Search Everything' }, ...searches];
      });
  }

  getSavedSearch(): void {
    console.log(this.savedSearchSelection);
  }

  getReports(payload: any): void {}
}
