import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
const dateFormatLong = 'MM/DD/YYYY hh:mm A';
const dateFormatShort = 'MM/DD';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  transform(value: any, args?: string): string {
    switch (args) {
      case 'long':
        return moment(value).format(dateFormatLong);
      case 'short':
        return moment(value).format(dateFormatShort);
      default:
        return moment().set('month', value).format(args);
    }
  }
}
