<!-- tabs -->
<nav class="mb-4">
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <a
      *ngFor="let tab of tabs; let i = index; trackBy: trackByIndex"
      class="nav-item nav-link"
      [id]="tab?.id"
      data-toggle="tab"
      [href]="tab?.href"
      role="tab"
      [attr.aria-controls]="tab?.id"
      aria-selected="false"
      (click)="goToTab(tab?.id)"
      [ngClass]="{ active: currentTab === tab?.id }"
    >
      {{ tab?.title }}
    </a>
  </div>
</nav>

<!-- tab content -->
<div class="tab-content" id="nav-tabContent">
  <div class="tab-pane fade show active" id="{{ 'nav-' + currentTab }}" role="tabpanel" [attr.aria-labelledby]="currentTab">
    <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
  </div>
</div>
