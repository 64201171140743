import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VideoTutorialsRoutingModule } from './video-tutorials-routing.module';
import { VideoTutorialsComponent } from './video-tutorials.component';

@NgModule({
  imports: [CommonModule, VideoTutorialsRoutingModule],
  declarations: [VideoTutorialsComponent]
})
export class VideoTutorialsModule {}
