<app-project-filters (filterObject)="getFilterObject($event)"></app-project-filters>

<div class="container">
  <!-- projects -->
  <app-project-list
    [projects]="projects"
    [filters]="filterObj"
    [metaData]="model"
    (highlightProp)="getHighlightProps($event)"
    (clearHighlighted)="clearHighlighted($event)"
  ></app-project-list>

  <!-- pagination -->
  <ng-container *ngIf="projects?.length">
    <app-pagination
      [collectionSize]="totalItems"
      [pageSize]="model?.limit"
      [page]="model.page"
      (pageChange)="getPageChange($event)"
    ></app-pagination>
  </ng-container>
</div>
