import { Directive, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { omitBy, isNull, cloneDeep, mergeWith } from 'lodash';

@Directive({
  selector: '[appBindParams]'
})
export class BindParamsDirective {
  @Input('appBindParams') queryParams: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnChanges(): void {
    const data = cloneDeep(JSON.parse(this.queryParams));
    const mergedData = mergeWith({}, this.activatedRoute.snapshot.queryParams, data, (a, b) => (b === null ? b : undefined));
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { ...omitBy(mergedData, isNull) }
    });
  }
}
