import * as moment from 'moment';
import { ProjectFilter } from '@app/@core/models/project-meta-data';

/*
Project Filter/Sort Details:
1. Bidding
Bid Date Range: Today - Any time in the future
Sort: youngest to oldest (so the projects with the bid date furthest out are at the top)
2. Plan Holders
Bid Date Range: Today - Any time in the future
Sort: oldest to youngest (so the projects with the bid date furthest out are at the bottom)
3. Results
Bid Date Date Range: Today - 3 months in the past
Sort: youngest to oldest (so the projects with the most recent bid date are at the top)
4. Tabs
Bid Date Date Range: Today - 3 months in the past
Sort: youngest to oldest (so the projects with the most recent bid date out are at the top)
5. Plans
Bid Date Range: 30 days in past - Any time in the future
Sort: Sort: youngest to oldest (so the projects with the bid date furthest are at the top)
*/

export const filters: ProjectFilter[] = [
  {
    category: 'bidding',
    name: 'Bidding',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(1, 'year').format('YYYY-MM-DD'),
    sortBy: 'BidDateTime',
    sortOrder: 'desc',
    tab: 'bid-details'
  },
  {
    category: 'planHolders',
    name: 'Plan Holders',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(1, 'year').format('YYYY-MM-DD'),
    sortBy: 'BidDateTime',
    sortOrder: 'asc',
    tab: 'plan-holders'
  },
  {
    category: 'results',
    name: 'Results',
    startDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    sortBy: 'BidDateTime',
    sortOrder: 'desc',
    tab: 'bid-results'
  },
  {
    category: 'tabs',
    name: 'Tabs',
    startDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    sortBy: 'BidDateTime',
    sortOrder: 'desc',
    tab: 'tabulations'
  },
  {
    category: 'plans',
    name: 'Plans',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(1, 'year').format('YYYY-MM-DD'),
    sortBy: 'BidDateTime',
    sortOrder: 'desc',
    tab: 'plans'
  }
];
