import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { VideoTutorialsComponent } from './video-tutorials.component';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  {
    path: 'videos',
    component: VideoTutorialsComponent,
    data: { title: 'Video Tutorials' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class VideoTutorialsRoutingModule {}
