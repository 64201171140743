import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

import { combineLatest } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { untilDestroyed } from '@app/@core';
import { LocalStorageService, ExportModalComponent } from '@app/@shared';

import { ProjectsService } from '../projects.service';
import { Project } from '@app/@core/models';
import { ProjectFilter, ProjectMetaData } from '@app/@core/models/project-meta-data';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectListComponent implements OnInit, OnDestroy {
  @ViewChild('tooltipTop', { static: false }) exportTooltipTop: NgbTooltip;
  @ViewChild('tooltipBottom', { static: false }) exportTooltipBottom: NgbTooltip;
  data: Project[] = [];
  @Input()
  set projects(data: Project[]) {
    this.data = [...data];
  }
  @Input() filters: ProjectFilter;
  @Input() metaData: ProjectMetaData;
  @Output() clearHighlighted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() highlightProp: EventEmitter<any> = new EventEmitter<any>();
  highlighted = false;
  order = '';
  location: string = null;
  selectAllProjects = false;

  constructor(
    private localStorageService: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private projectsService: ProjectsService
  ) {}

  ngOnInit() {
    const combinedParams = combineLatest([this.activatedRoute?.params, this.activatedRoute?.queryParams]);
    combinedParams.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe(([params, queryParams]) => {
      this.order = queryParams.sortOrder;
      this.location = params?.location;
    });
  }

  ngOnDestroy() {}

  trackById(index: number, project: Project): string {
    return project?.Id;
  }

  manageSort(event: MouseEvent, key: string): void {
    this.order = this.order === 'asc' ? 'desc' : 'asc';
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        ...this.activatedRoute.snapshot.queryParams,
        sortBy: key,
        sortOrder: this.order
      }
    });
  }

  manageHighlighted(event: MouseEvent): void {
    if (!event) return;
    this.highlighted = !this.highlighted;
  }

  hideUnhighlighted(highlighted): boolean {
    return !highlighted && this.highlighted ? true : false;
  }

  clearHighlights(status: boolean): void {
    this.clearHighlighted.emit(status);
    const highlightData = { ...this.metaData, enabled: false };
    this.localStorageService.setItem('projectMetaData', highlightData);
  }

  changeHandler(event: MouseEvent, project: Project): void {
    const checked = (event?.target as HTMLInputElement)?.checked;
    this.hideTooltip();
    this.data = this.data.map(pr => (pr?.Id === project?.Id ? { ...pr, checked } : pr));
  }

  changeAllHandler(event: MouseEvent): void {
    const checked = (event?.target as HTMLInputElement)?.checked;
    this.hideTooltip();
    this.data = this.data.map(pr => ({ ...pr, checked }));
  }

  openExport(): void {
    const ids = this.data.filter(pr => pr.checked).map(({ Id }) => Id);
    if (!ids?.length) {
      this.showTooltip();
    } else {
      const modalRef = this.modalService.open(ExportModalComponent, { size: 'lg' });
      modalRef.componentInstance.projectIds = ids;
      modalRef.componentInstance.service = this.projectsService;
      modalRef.result.then(result => {
        this.selectAllProjects = false;
        this.hideTooltip();
        this.data.map(pr => ({ ...pr, checked: false }));
      });
    }
  }

  getHighlightData(param: any): void {
    this.highlightProp.emit(param);
  }

  goToDetails(id: string): void {
    this.localStorageService.setItem('projectFilter', this.filters);
    this.localStorageService.setItem('projectMetaData', this.metaData);
    this.router.navigate([this.location, 'project', id, this.filters?.tab]);
  }

  showTooltip(): void {
    this.exportTooltipTop.open();
    this.exportTooltipBottom.open();
  }

  hideTooltip(): void {
    this.exportTooltipTop.close();
    this.exportTooltipBottom.close();
  }
}
