import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed } from '@app/@core';

import { cloneDeep, trimStart, omit } from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.css']
})
export class DateRangeComponent implements OnInit, OnDestroy {
  startDate: string = moment().format('YYYY-MM-DD');
  endDate: string = moment().add(1, 'year').format('YYYY-MM-DD');

  customSortDropdownConfig: any = {
    type: 'sort',
    options: [
      { id: 'BidDateTime', label: 'Bid Date Ascending', selected: false },
      { id: '-BidDateTime', label: 'Bid Date Descending', selected: false },
      { id: 'ProjectType', label: 'Project Type Ascending', selected: false },
      { id: '-ProjectType', label: 'Project Type Descending', selected: false }
    ],
    selectedOption: 'all'
  };

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(queryParams => {
      const { startDate, endDate, sortBy, sortOrder } = queryParams;
      this.startDate = startDate;
      this.endDate = endDate;
      this.customSortDropdownConfig.selectedOption = sortOrder === 'desc' ? `-${sortBy}` : sortBy;
    });
  }

  ngOnDestroy() {
    // Needed for automatic unsubscribe with untilDestroyed
  }

  getCustomRange(range: any): void {
    const { startDate, endDate } = range;
    this.startDate = moment(startDate).format('YYYY-MM-DD');
    this.endDate = moment(endDate).format('YYYY-MM-DD');
    this.updateQueryParams({ startDate: this.startDate, endDate: this.endDate });
  }

  onSortOptionChange(value: any): void {
    const { id } = value;
    const sortBy = trimStart(id, '-');
    const sortOrder = id.includes('-') ? 'desc' : 'asc';
    this.updateQueryParams({ sortBy, sortOrder });
  }

  updateQueryParams(params: any): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { ...omit(this.activatedRoute.snapshot.queryParams, [...Object.keys(params)]), ...params }
    });
  }
}
