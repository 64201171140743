import { Project } from '@app/@core/models';
import { dateRangeArray } from './dateRangeArray';
import { compact, orderBy, get, pick, pickBy, flatMap, uniqBy } from 'lodash';
import moment from 'moment';

/*
Filter Validation:
1. If there is no keyword, disable the first drop down.
2. All 4 filter elements should work together, unless there is no keyword. If there is no keyword, then drop downs 2 and 3 work together.
3. If there is no keyword AND drop downs 2 and 3 say "All Projects" this is fine.
  Just highlight all projects when the user turn highlighting on.
*/

export function projectsWithFilterAndSort(props, projects: Project[]): Project[] {
  const { sortBy, sortOrder, startDate, endDate } = props;
  const filtered = rangeFilter(projects, startDate, endDate);
  const sorted = sortProjects(sortBy, sortOrder, filtered);

  return sorted;
}

export function projectsToHighlight(props, projects): string[] {
  const { contains, startDate, endDate, hStart, hEnd, key, term, searches, keywords } = pickBy(props);

  const searchResults = searchFilter(projects, searches, keywords, key, term);
  const filterResults = propFilter(projects, searchResults, contains, term, hStart, hEnd);
  const range = rangeFilter(filterResults, startDate, endDate);
  const highlightRange = hStart && hEnd ? rangeFilter(range, hStart, hEnd) : range;

  return highlightRange.map(project => project.Id);
}

export function searchFilter(projects, searches, keywords, key, term): Project[] {
  switch (true) {
    case searches && keywords && !term:
      const savedSearch = searches.split(',').map(k => keywords.split(',').map(keyword => filterByMultipleKeys(projects, k, keyword)));
      return uniqBy(flatMap(compact(flatMap(savedSearch))), '_id') || [];
    case term && key === 'all':
      return searchEverything(projects, term) || [];
    case term && key !== 'all':
      return filterByMultipleKeys(projects, key, term);
    default:
      break;
  }
}

export function propFilter(projects, searchResults, contains, term, hStart, hEnd): Project[] {
  switch (true) {
    case contains && contains === 'all':
      if (searchResults.length) return searchResults;
      else return projects;
    case contains && contains !== 'all':
      if (searchResults.length) return searchResults.filter(p => p[contains] && p[contains].length);
      else return projects.filter(p => p[contains] && p[contains].length);
    case !contains:
      if (searchResults.length) return searchResults;
      else if (!term && hStart && hEnd) return projects;
      else return [];
  }
}

export function rangeFilter(projects: Project[], startDate, endDate): Project[] {
  const dateRange = startDate && endDate && dateRangeArray(startDate, endDate);
  const filtered = projects.filter(p => dateRange.includes(moment(p.BidDateTime).format('YYYY-MM-DD')));

  return filtered;
}

export function sortProjects(sortBy: string, sortOrder: string, projects: Project[]): Project[] {
  const sortProp = sortBy === 'BidDateTime' ? (obj: Project) => moment(obj.BidDateTime) : sortBy;
  const sorted = orderBy(projects, sortProp, [sortOrder]);
  return sorted;
}

/*
  Project Search Details:
  1, Project Title - Title
  2. Bid Details - AmtekId, Title, PreBidInfo, BidInfo, Engineer1, Engineer2, Engineer3, Owner1, Owner2, Notes1, Notes2
  3. Plan Holders - Company, Phone, Fax, Email
  4. Bid Results - Company
  5. Bid Items - Description
  6. Bid Tabs - Company, Item Description
  7. Saved Searches - BidItems, Project Title
  8. Search Everything - All the above keys
 */

export function searchEverything(projects, term): Project[] {
  const keys = ['Title', 'BidDetails', 'BidItems', 'PlanHolders', 'BidResults', 'Tabulations'];
  const results = keys.map(key => filterByMultipleKeys(projects, key, term));
  return uniqBy(flatMap(compact(flatMap(results))), '_id') || [];
}

export function filterByMultipleKeys(projects: Project[], prop: string, term: string): Project[] {
  const regex = new RegExp(term, 'i');
  const filtered = compact(projects.filter(project => (checkProps(project, prop, regex) ? project : undefined)));
  return filtered;
}

const searchKeys = prop => {
  switch (prop) {
    case 'Title':
      return ['Title'];
    case 'BidDetails':
      return ['AmtekId', 'Title', 'PreBidInfo', 'BidInfo', 'Engineer1', 'Engineer2', 'Engineer3', 'Owner1', 'Owner2', 'Notes1', 'Notes2'];
    case 'BidItems':
      return ['Description'];
    case 'PlanHolders':
      return ['Company', 'Phone', 'Fax', 'Email'];
    case 'BidResults':
      return ['Company'];
    case 'Tabulations':
      return ['BidderName', 'Description'];
    default:
      return;
  }
};

export function checkProps(project: Project, type: string, regex: RegExp) {
  let result, result1, result2, match, match1, match2;
  const pr = get(project, type);
  const keys = searchKeys(type);
  switch (type) {
    case 'Title':
      result = get(project, 'Title');
      match = regex.test(result);
      break;
    case 'BidDetails':
      result = pick(project, keys);
      match = compact(keys.map(key => regex.test(result[key])))[0];
      break;
    case 'BidItems':
      result = compact(flatMap(flatMap(project.BidItems.map(bItem => bItem['Sections'])).map(section => section['BidItems']))).map(
        bItem => bItem.Description
      );
      match = compact(result.map(re => regex.test(re)))[0];
      break;
    case 'Tabulations':
      result1 = pr.map(p => pick(p, keys));
      result2 = compact(
        flatMap(
          flatMap(flatMap(flatMap(project.Tabulations).map(tab => tab['BidForms'])).map(bForm => bForm['Sections'])).map(
            section => section['BidItems']
          )
        )
      ).map(bItem => bItem.Description);
      match1 = compact(flatMap(keys.map(key => result1.map(r => regex.test(r[key])))))[0];
      match2 = compact(result2.map(re => regex.test(re)))[0];
      match = match1 || match2;
      break;
    case 'PlanHolders':
      result = pr.PlanHolders.map(p => pick(p, keys));
      match = compact(flatMap(keys.map(key => result.map(r => regex.test(r[key])))))[0];
      break;
    case 'BidResults':
      result = pr.Bidders.map(p => pick(p, keys));
      match = compact(flatMap(keys.map(key => result.map(r => regex.test(r[key])))))[0];
      break;
    default:
      break;
  }
  return match;
}
