<!-- bid info -->
<div class="mb-4 border rounded p-3">
  <div class="row pb-3">
    <div class="col-2 col-md-2 font-weight-bold">ID</div>
    <div class="col-4 col-md-4">
      <span appHighlightKeyword>{{ bidDetails?.AmtekId }}</span>
      <span class="badge badge-pill badge-success" *ngIf="bidDetails?.Addendum">A{{ bidDetails?.Addendum }}</span>
    </div>
    <div class="col-3 col-md-2 font-weight-bold">Estimate</div>
    <div class="col-3 col-md-4">{{ bidDetails?.Estimate }}</div>
  </div>
  <div class="row bg-light py-3">
    <div class="col-12 col-md-2 font-weight-bold">Prebid</div>
    <div class="col-12 col-md-10" appHighlightKeyword>{{ bidDetails?.PrebidDateTime | dateFormat: 'long' }} {{ bidDetails?.PreBidInfo }}</div>
  </div>
  <div class="row py-3">
    <div class="col-12 col-md-2 font-weight-bold">Bid</div>
    <div class="col-12 col-md-10" appHighlightKeyword>{{ bidDetails?.BidDateTime | dateFormat: 'long' }} {{ bidDetails?.BidInfo }}</div>
  </div>
  <div class="row bg-light py-3">
    <div class="col-12 col-md-2 font-weight-bold">Arch./Eng.</div>
    <div class="col-12 col-md-10">
      <div appHighlightKeyword>{{ bidDetails?.Engineer1 }}</div>
      <div appHighlightKeyword>{{ bidDetails?.Engineer2 }}</div>
      <div appHighlightKeyword>{{ bidDetails?.Engineer3 }}</div>
    </div>
  </div>
  <div class="row py-3">
    <div class="col-12 col-md-2 font-weight-bold">Owner</div>
    <div class="col-12 col-md-10">
      <div appHighlightKeyword>{{ bidDetails?.Owner1 }}</div>
      <div appHighlightKeyword>{{ bidDetails?.Owner2 }}</div>
    </div>
  </div>
  <div class="row bg-light py-3">
    <div class="col-12 col-md-2 font-weight-bold">Plans</div>
    <div class="col-12 col-md-10">{{ bidDetails?.PlansInfo }}</div>
  </div>
  <div class="row pt-3">
    <div class="col-12 col-md-2 font-weight-bold">Notes</div>
    <div class="col-12 col-md-10">
      <div appHighlightKeyword>{{ bidDetails?.Note1 }}</div>
      <div appHighlightKeyword>{{ bidDetails?.Note2 }}</div>
    </div>
  </div>
</div>

<!-- bid items -->
<div>
  <div class="p-3 bg-danger text-white rounded" [ngClass]="{ 'd-none': bidDetails?.BidItems?.length }">No bid items yet.</div>
  <div class="table-responsive">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th scope="col">ID 1</th>
          <th scope="col">ID 2</th>
          <th scope="col">Description</th>
          <th scope="col">Unit</th>
          <th scope="col">Qty</th>
          <th scope="col">Unit Price</th>
        </tr>
      </thead>
      <tbody *ngFor="let bItem of bidDetails?.BidItems; let i = index">
        <tr>
          <th colspan="6" class="bid-form-name text-white font-weight-bold">{{ bItem?.FormName }}</th>
        </tr>
        <ng-container *ngFor="let section of bItem?.Sections; let idx = index">
          <tr>
            <th colspan="6" class="bg-light">{{ section?.SectionName }}</th>
          </tr>
          <tr *ngFor="let bI of section?.BidItems; let idex = index">
            <td>{{ bI?.ItemNo1 }}</td>
            <td>{{ bI?.ItemNo2 }}</td>
            <td appHighlightKeyword>{{ bI?.Description }}</td>
            <td>{{ bI?.Unit }}</td>
            <td>{{ bI?.Quantity }}</td>
            <td>{{ bI?.UnitPrice }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
