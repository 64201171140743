import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { untilDestroyed } from '@app/@core';
import { LocalStorageService } from '../../services/local-storage.service';

import { SettingsService } from '@app/settings/settings.service';
import { SavedSearch } from '@app/@core/models';

@Component({
  selector: 'app-highlights',
  templateUrl: './highlights.component.html',
  styleUrls: ['./highlights.component.css']
})
export class HighlightsComponent implements OnInit, OnDestroy {
  @Input() type: string = null;
  @Output() clearHighlights: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() hideHighlighted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() openExport: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() highlightData: EventEmitter<string> = new EventEmitter();
  search = new FormControl();
  model: any = {
    filter: '',
    date: '',
    search: 'ALL',
    keywords: '',
    searchTerm: '',
    savedSearch: false,
    presetEnabled: false,
    customEnabled: false
  };
  private data: BehaviorSubject<any> = new BehaviorSubject<any>({ ...this.model });
  public data$: Observable<any> = this.data.asObservable();
  filterDropdownConfig: any = {
    type: 'filter',
    options: [
      { id: 'ALL', label: 'All Projects', selected: true },
      { id: 'BidItems', label: 'Has Bid Items', selected: false },
      { id: 'PlanHolders', label: 'Has Plan Holders', selected: false },
      { id: 'BidResults', label: 'Has Bid Results', selected: false },
      { id: 'BidTabs', label: 'Has Bid Tabs', selected: false }
    ],
    selectedOption: 'ALL'
  };
  dateDropdownConfig: any = {
    type: 'date',
    options: [
      { id: 'ALL', label: 'All Projects' },
      { id: '2days', label: 'Added in the last 2 days' },
      { id: '7days', label: 'Added in the last 7 days' }
    ],
    selectedOption: 'ALL'
  };
  searchDropdownConfig: any = {
    type: 'search',
    searchTerm: '',
    options: [
      { id: 'ALL', label: 'Search Everything' },
      { id: 'Title', label: 'Search Project Title' },
      { id: 'BidDetails', label: 'Search Bid Details' },
      { id: 'BidItems', label: 'Search Bid Items' },
      { id: 'PlanHolders', label: 'Search Plan Holders' },
      { id: 'BidResults', label: 'Search Bid Results' },
      { id: 'BidTabs', label: 'Search Bid Tabs' }
    ],
    selectedOption: 'ALL'
  };
  savedSearchesDropdownConfig: any = {
    type: 'searches',
    options: [],
    selectedOption: 'ALL'
  };
  savedSearchSelection: SavedSearch = { Id: 'ALL', Title: 'Search Everything', Keywords: '', DateAddedOption: '', UserId: '' };
  showCloseIcon = false;

  constructor(private modalService: NgbModal, private localStorageService: LocalStorageService, private settingsService: SettingsService) {
    this.settingsService.getSavedSearches().subscribe((searches: SavedSearch[]) => {
      this.savedSearchesDropdownConfig.options = [{ Id: 'ALL', Title: 'Search Everything' }, ...searches];
    });
  }

  ngOnInit() {
    this.prefillOptions();
    this.manageSearch();
    console.log(this.type);
    this.data$.pipe(untilDestroyed(this)).subscribe(data => {
      this.highlightData.emit({ ...data });
    });
  }

  ngOnDestroy() {}

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1?.Id === c2?.Id : c1 === c2;
  }

  prefillOptions(): void {
    const combinedParams = combineLatest([
      this.localStorageService.getItem('projectMetaData'),
      this.localStorageService.getItem('txdotMetaData')
    ]);
    combinedParams.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe(([local, txdot]) => {
      const data = this.type === 'others' ? { ...local } : { ...txdot };
      if (!data) return;

      this.searchDropdownConfig.selectedOption = data?.search?.hSearchIn || data?.hSearchIn || 'ALL';
      this.searchDropdownConfig.searchTerm = data?.search?.hKeywords || data?.hKeywords;
      this.filterDropdownConfig.selectedOption = data?.filter?.hTerms || data?.hTerms || 'ALL';
      this.dateDropdownConfig.selectedOption = data?.hAddedDate || 'ALL';
      this.search.setValue(data?.search?.hKeywords || data?.hKeywords);
      this.model = {
        filter: data?.filter?.hTerms || data?.hTerms,
        date: data?.hAddedDate,
        search: data?.search?.hSearchIn || data?.hSearchIn,
        searchTerm: data?.search?.hKeywords || data?.hKeywords,
        presetEnabled: data?.presetEnabled,
        customEnabled: data?.customEnabled
      };
      this.data.next({ ...this.model });
    });
  }

  manageSearch(): void {
    this.search.valueChanges.pipe(debounceTime(1000)).subscribe(search => {
      this.model.searchTerm = search;
      this.data.next({ ...this.model });
    });
  }

  managePresetHighlights(event: MouseEvent): void {
    if (event) {
      this.model.presetEnabled = !this.model.presetEnabled;
      this.model.customEnabled = false;

      this.search.reset();
      this.searchDropdownConfig.selectedOption = 'ALL';
      this.searchDropdownConfig.searchTerm = null;
      this.filterDropdownConfig.selectedOption = 'ALL';
      this.dateDropdownConfig.selectedOption = 'ALL';

      this.data.next({ ...this.model });
      if (!this.model.presetEnabled) this.clearHighlights.emit(true);
    }
  }

  manageCustomHighlights(event: MouseEvent | KeyboardEvent): void {
    if (event instanceof MouseEvent || event instanceof KeyboardEvent) {
      this.model.customEnabled = !this.model.customEnabled;
      this.model.presetEnabled = false;
      this.model.savedSearch = undefined;
      this.model.date = null;

      this.savedSearchSelection = { Id: 'ALL', Title: 'Search Everything', Keywords: '', DateAddedOption: '', UserId: '' };

      this.data.next({ ...this.model });
      if (!this.model.customEnabled) this.clearHighlights.emit(true);
    }
  }

  hideHighlightedRows(event) {
    if (event) this.hideHighlighted.emit(event);
  }

  openExportModal(event) {
    if (event) this.openExport.emit(event);
  }

  getHighlightData(data: any, type: string): void {
    if (data && type)
      switch (type) {
        case 'filter':
          this.model.filter = data?.id;
          break;
        case 'date':
          this.model.date = data?.id;
          break;
        case 'search':
          this.model.search = data?.id;
          break;
        default:
          break;
      }
    this.data.next({ ...this.model });
  }

  getSavedSearch(search: any): void {
    this.model.savedSearch = true;
    this.model.keywords = this.savedSearchSelection?.Keywords;
    this.model.date = this.savedSearchSelection?.DateAddedOption;
    this.data.next({ ...this.model });
  }
}
