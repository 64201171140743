import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { untilDestroyed } from '@app/@core';
import { LocalStorage } from '@app/@shared';

import { AuthenticationService } from '@app/auth/authentication.service';

import * as moment from 'moment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() locationChange: EventEmitter<string> = new EventEmitter();
  @LocalStorage isAuthenticated: boolean;

  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService
      .isUserAuthenticated()
      .pipe(untilDestroyed(this))
      .subscribe(authenticated => {
        this.isAuthenticated = authenticated;
      });
  }

  ngOnDestroy() {}

  isActive(type: string): boolean {
    return this.router.url?.includes(type);
  }

  goToLocation(location: string): void {
    this.locationChange.emit(location);
    if (location === 'txdot') this.router.navigate(['txdot', 'projects']);
    else
      this.router.navigate(['projects/', location], {
        queryParams: {
          category: 'bidding',
          sortBy: 'BidDateTime',
          sortOrder: 'asc',
          startDate: moment().format('YYYY-MM-DD'),
          endDate: moment().add(1, 'year').format('YYYY-MM-DD'),
          page: 1,
          limit: 150
        }
      });
  }

  goToRoute(type: string): void {
    if (type === 'tutorials') this.router.navigate(['tutorials', 'videos']);
    else if (type === 'settings') this.router.navigate(['settings', 'saved-searches']);
  }
}
