<ul class="list-group mb-4">
  <li class="list-group-item font-weight-bold bg-light">Bid Docs</li>
  <li class="list-group-item" [ngClass]="{ 'd-none': bidDocs?.length }">No docs yet.</li>
  <li class="list-group-item" *ngFor="let bDoc of bidDocs; let i = index">
    <a href="#">{{ bDoc?.FileDescription }}</a>
  </li>
</ul>

<ul class="list-group mb-4" *ngFor="let pSheet of planSheets; let i = index">
  <li class="list-group-item bg-light">
    <div class="d-flex justify-content-between align-items">
      <div class="font-weight-bold">
        Plans <span *ngIf="pSheet?.Suffix">- {{ pSheet?.Suffix }}</span>
      </div>
      <div>
        <button type="button" class="btn btn-primary btn-sm">Show Thumbnails</button>
        <button type="button" class="btn btn-primary btn-sm d-none">Hide Thumbnails</button>
      </div>
    </div>
  </li>
  <li class="list-group-item" [ngClass]="{ 'd-none': pSheet?.PlanSheets?.length }">No plans yet.</li>
  <li class="list-group-item"><a href="#">Full Set - $25.75 </a> <span>(103 sheets * $0.25/sheet)</span></li>
  <li class="list-group-item" *ngFor="let sheet of pSheet?.PlanSheets; let idx = index">
    <div class="row">
      <div class="col-12 col-md-2 col-lg-1">
        {{ sheet?.PageNumber | slice: 0 - 1 }}
      </div>
      <div class="col-12 col-md-10 col-lg-11">
        <a href="#">{{ sheet?.PageFileDescription }}</a>
      </div>
    </div>
  </li>
</ul>
