<div class="container">
  <section class="row my-4">
    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h2>My Saved Searches</h2>
        </div>
        <div>
          <button type="button" class="btn btn-primary" (click)="openCard($event)">{{ open ? 'Cancel' : 'Add New Scan'
            }}</button>
        </div>
      </div>
      <div>A saved search will highlight all projects with a title or bid items that match your keywords.</div>
    </div>
  </section>
  <section class="row">
    <div class="col-12">
      <!-- Create Saved Search -->
      <app-searches-form *ngIf="open" [config]="defaultConfig"
        (searchData)="getSavedSearchPayload($event, null, 'create')" (deleteData)="getDeleteId($event)">
      </app-searches-form>

      <!-- Edit existing Saved Searches -->
      <ng-container *ngFor="let search of savedSearches; let i = index">
        <app-searches-form [config]="search" (searchData)="getSavedSearchPayload($event, search?.Id, 'edit')"
          (deleteData)="getDeleteId($event)"></app-searches-form>
      </ng-container>
    </div>
  </section>
</div>