import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { pickBy } from 'lodash';

import { LocalStorageService } from '@app/@shared';

import { Bid, Filter } from './@models';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class TxdotService {
  baseURL = `${environment?.txdotBaseURL}`;
  reportsUrl = `${environment?.txdotReportsBaseURL}`;

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {}

  getProjects(metaData: any): Observable<any> {
    const { startDate, endDate, sortBy, sortOrder, page, limit } = metaData;
    const params = pickBy({ startDate, endDate, sortBy, sortOrder, page, limit });
    const checkProps: boolean = Object.values(params)?.length === 6;

    if (checkProps)
      return this.http.get(`${this.baseURL}/projects`, { params }).pipe(
        map((data: any) => data),
        catchError(() => of('Error, could not load projects'))
      );
  }

  getDateFilters(): Observable<Filter[]> {
    return this.http.get(`${this.baseURL}/filters`).pipe(
      map((data: any) => data?.Data?.Filters),
      catchError(() => of('Error, could not load filters'))
    );
  }

  getProjectIdsToHighlight(metaData: any): Observable<string[]> {
    const { hTerms, startDate, endDate, hSearchIn, hKeywords, savedSearch, hAddedDate } = metaData;
    this.localStorageService.setItem('txdotMetaData', metaData);
    const params = pickBy({
      startDate,
      endDate,
      hSearchIn,
      hKeywords,
      savedSearch,
      hTerms,
      hAddedDate
    });

    const checkProps: boolean = Object.values(params)?.length >= 3;
    if (checkProps)
      return this.http
        .get<string[]>(`${this.baseURL}/highlights`, { params })
        .pipe(
          map((data: any[]) => data?.['Data']),
          catchError(() => of('Error, could not get project ids'))
        );
  }

  getProject(id: string): Observable<Bid | string> {
    return this.http.get<Bid>(`${this.baseURL}/projects/${id}`).pipe(
      map((data: Bid) => data?.['Data']),
      catchError(() => of('Error, could not load this project'))
    );
  }

  getReports(payload: any): Observable<any> {
    const { type, format, Ids } = payload;
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.post(`${this.reportsUrl}?type=${type}&format=${format}`, { Ids }, { ...httpOptions }).pipe(
      map((data: any) => data),
      catchError(error => of(error))
    );
  }
}
