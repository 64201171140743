import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private behaviorSubjects: Map<string, BehaviorSubject<any>>;

  constructor() {
    this.behaviorSubjects = new Map<string, BehaviorSubject<any>>();
  }

  /**
   * Returns the behaviorSubject by identifier. If it does not exist, a new one is created but not pre-filled with any value.
   * @param identifier The localStorage identifier
   */
  private getBehaviorSubject(identifier: string): BehaviorSubject<any> {
    let behaviorSubject: BehaviorSubject<any> = this.behaviorSubjects.get(identifier);
    if (!behaviorSubject) {
      behaviorSubject = new BehaviorSubject<any>(null);
      this.behaviorSubjects.set(identifier, behaviorSubject);
    }

    return behaviorSubject;
  }

  /**
   * Gets an item from localStorage
   * @param identifier Identifier of the storage object.
   */
  public getItem(identifier: string): BehaviorSubject<any> {
    const behaviorSubject = this.getBehaviorSubject(identifier);
    const item = localStorage.getItem(identifier);
    behaviorSubject.next(JSON.parse(item));
    return behaviorSubject;
  }

  /**
   * Stores an item and emits the new value to all its subscribers
   * @param identifier LocalStorage identifier
   * @param object the object that should be stored.
   */
  public setItem(identifier: string, object: any): void {
    const data = JSON.stringify(object);
    localStorage.setItem(identifier, data);
    this.getBehaviorSubject(identifier).next(object);
  }

  /**
   * Updates an item and emits the updated value to all its subscribers
   * @param identifier LocalStorage identifier
   * @param object the object that should be updated.
   */
  public updateItem(identifier: string, object: any): void {
    const current = JSON.parse(localStorage.getItem(identifier));
    const updated = JSON.stringify({ ...current, ...object });
    localStorage.setItem(identifier, updated);
    this.getBehaviorSubject(identifier).next(object);
  }

  public removeItem(identifier: string): void {
    localStorage.removeItem(identifier);
    this.getBehaviorSubject(identifier).next(null);
  }

  /**
   * Clears the localStorage and tells all subscribers of all items that the value is now null.
   */
  public clear() {
    localStorage.clear();
    this.behaviorSubjects.forEach((behaviorSubject: BehaviorSubject<any>) => {
      behaviorSubject.next(null);
    });

    console.log('Local storage cleared');
  }
}
