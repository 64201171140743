<div class="py-3" *ngIf="type">
  <div class="row no-gutters">
    <div class="col-12 col-lg-4 mb-3 mb-lg-0">
      <div class="bg-light border rounded p-3 mr-0 mr-lg-3">
        <div><label class="font-weight-bold mb-1">My Preset Scans</label></div>
        <div class="mb-2" *ngIf="savedSearchesDropdownConfig.options?.length">
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            [compareWith]="compareFn"
            [(ngModel)]="savedSearchSelection"
            (change)="getSavedSearch($event)"
          >
            <option *ngFor="let option of savedSearchesDropdownConfig?.options; let i = index" [ngValue]="option">
              {{ option?.Title }}
            </option>
          </select>
        </div>
        <div>
          <button
            *ngIf="savedSearchesDropdownConfig.options?.length"
            type="button"
            class="btn btn-warning"
            (click)="managePresetHighlights($event)"
          >
            <span class="text-white">Scan</span>
            <i class="fas fa-times fa-lg text-white ml-1" [ngClass]="{ 'd-none': !model?.presetEnabled }"></i>
          </button>
          <button
            *ngIf="model?.presetEnabled"
            type="button"
            class="btn btn-dark"
            title="Hide Unhighlighted"
            (click)="hideHighlightedRows($event)"
          >
            Hide Unhighlighted
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-8">
      <div class="bg-light border rounded p-3">
        <div>
          <label class="font-weight-bold mb-1">Custom Scan</label>
        </div>
        <div class="form-row mb-2">
          <div class="col-12 col-lg-3 mb-2 mb-lg-0">
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Type Keyword"
              [formControl]="search"
              (keydown.enter)="manageCustomHighlights($event)"
            />
          </div>
          <div class="col-12 col-lg-3 mb-2 mb-lg-0">
            <app-dropdown
              [config]="searchDropdownConfig"
              (selectedOption)="getHighlightData($event, 'search')"
              [disabled]="!model.searchTerm"
            ></app-dropdown>
          </div>
          <div class="col-12 col-lg-3 mb-2 mb-lg-0">
            <app-dropdown [config]="filterDropdownConfig" (selectedOption)="getHighlightData($event, 'filter')"> </app-dropdown>
          </div>
          <div class="col-12 col-lg-3 mb-2 mb-lg-0">
            <app-dropdown [config]="dateDropdownConfig" (selectedOption)="getHighlightData($event, 'date')"> </app-dropdown>
          </div>
        </div>
        <div>
          <button type="button" class="btn btn-warning" (click)="manageCustomHighlights($event)">
            <span class="text-white">Scan</span>
            <i class="fas fa-times fa-lg text-white ml-1" [ngClass]="{ 'd-none': !model?.customEnabled }"></i>
          </button>
          <button
            *ngIf="model?.customEnabled"
            type="button"
            class="btn btn-dark"
            title="Hide Unhighlighted"
            (click)="hideHighlightedRows($event)"
          >
            Hide Unhighlighted
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
