import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-project-highlight-settings-modal',
  templateUrl: './project-highlight-settings-modal.component.html',
  styleUrls: ['./project-highlight-settings-modal.component.css']
})
export class ProjectHighlightSettingsModalComponent implements OnInit, OnDestroy {
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  ngOnDestroy() {}

  close() {
    this.activeModal.dismiss();
  }
}
