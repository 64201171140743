import { NgModule } from '@angular/core';

import { SharedModule } from '@shared';
import { ProjectRoutingModule } from './project-routing.module';

import { ProjectComponent } from './project.component';
import { ProjectTabsComponent } from './project-tabs/project-tabs.component';

@NgModule({
  imports: [SharedModule, ProjectRoutingModule],
  declarations: [ProjectComponent, ProjectTabsComponent]
})
export class ProjectModule {}
