import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportsMockupComponent } from './reports-mockup/reports-mockup.component';

import { TxdotComponent } from './txdot.component';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  {
    path: 'projects',
    component: TxdotComponent,
    children: [{ path: 'reports', component: ReportsMockupComponent }]
  },
  { path: 'project-details', loadChildren: () => import('./project/project.module').then(m => m.ProjectModule) }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class TxdotRoutingModule {}
