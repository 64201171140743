import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private sharedData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public sharedData$: Observable<any> = this.sharedData.asObservable();

  constructor(private http: HttpClient) {}

  modifyData(data): void {
    this.sharedData.next(data);
  }
}
