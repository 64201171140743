<form class="card mb-4" [formGroup]="formGroup">
  <div class="card-header font-weight-bold">
    <ng-container *ngIf="config?.title">{{ config?.title }}</ng-container>
    <div class="row" *ngIf="!config?.title">
      <div class="col-12 col-md-6">
        <input
          type="text"
          class="form-control"
          id="title"
          aria-describedby="scantitle"
          placeholder="Name your new scan."
          formControlName="Title"
        />
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            id="keywords"
            placeholder="Enter keyword or phrase and click Add."
            aria-label="Keywords"
            aria-describedby="keywords"
            formControlName="keyword"
            (keydown.enter)="addKeywords($event)"
          />
          <div class="input-group-append">
            <button class="btn btn-primary" type="button" id="button-addon2" (click)="addKeywords($event)">Add</button>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <select class="form-control" id="exampleFormControlSelect1" formControlName="dateAdded">
            <option *ngFor="let option of dropdownOptions; let i = index" [ngValue]="option.id" >
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="border-top py-2">
      <p>Keyword List</p>
      <div formArrayName="Keywords">
        <ng-container *ngFor="let keyword of keywords?.value; let i = index">
          <span class="badge badge-pill badge-success m-1" *ngIf="keyword?.value" (click)="deleteKeywords(i)">
            <span class="mr-1">{{ keyword?.value }}</span>
            <i class="fas fa-times"></i>
          </span>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="card-footer text-muted">
    <div class="d-flex justify-content-end">
      <button type="button" class="btn btn-danger mr-2" (click)="sendDeletedData($event)">Remove</button>
      <button type="button" class="btn btn-primary" (click)="sendData($event)">{{ config?.Id ? 'Save' : 'Create Scan' }}</button>
    </div>
  </div>
</form>
