<div class="bg-white p-4">
  <div class="col-12">
    <ng-container *ngIf="!isAuthenticated">
      <a [routerLink]="['/login']">Sign In</a> |
      <a [routerLink]="['/register']">Create Account</a>
    </ng-container>
    <ng-container *ngIf="isAuthenticated">
      <a [routerLink]="['/logout']">Sign Out</a> |
      <a (click)="goToProjects($event)">Go To Projects</a>
    </ng-container>
  </div>
</div>
