import { Component, OnInit, OnDestroy } from '@angular/core';

import { cloneDeep } from 'lodash';

import { untilDestroyed } from '@app/@core';
import { SharedDataService } from '@app/@shared';

import { Project } from '@app/@core/models';

@Component({
  selector: 'app-bid-details-tab',
  templateUrl: './bid-details-tab.component.html',
  styleUrls: ['./bid-details-tab.component.css']
})
export class BidDetailsTabComponent implements OnInit, OnDestroy {
  bidDetails: Project;
  projectId: string;

  constructor(private sharedDataService: SharedDataService) {}

  ngOnInit() {
    this.getBidDetails();
  }

  ngOnDestroy() {}

  getBidDetails() {
    this.sharedDataService.sharedData$.pipe(untilDestroyed(this)).subscribe((bidDetails: any) => (this.bidDetails = cloneDeep(bidDetails)));
  }
}
